import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import DataService from "../../../services/data-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

export function UpdatePricesTable() {
  const { id } = useParams();
  const [space, setSpace] = useState([]);
  const [apuSelected, setApuSelected] = useState({
    id: "",
    capitulo: "",
    subcapitulo: "",
    apu: "",
    costo: "",
  });
  const dataService = new DataService();

  useEffect(() => {
    achitectsView();
  }, []);

  const achitectsView = () => {
    dataService.architects(id).then((rooms) => {
      setSpace(rooms);
    });
  };

  const capturePrices = () => {
    dataService
      .updatePrices(apuSelected.apu_id, apuSelected.costo)
      .then((response) => console.log(response))
      .catch((err) => console.log(err));
  };

  const selectApu = (room) => {
    setApuSelected(room);
  };

  const edit = () => {
    let newData = space;
    newData.map((apu) => {
      if (apu.apu_id === apuSelected.apu_id) {
        apu.costo = apuSelected.costo;
      }
    });
    setSpace(newData);
    capturePrices();
    achitectsView();
    alert("Precio actualizado");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApuSelected((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container">
      <div className="bg-primary position-fixed w-100 ">
        <h1 className="m-2">{space[0]?.espacio}</h1>
      </div>
      <div style={{ marginTop: "4rem" }}>
        <div className="table-responsive w-100">
          <table className="table table-bordered table-hover bg-white">
            <thead>
              <tr>
                <th>ID</th>
                <th>Capitulo</th>
                <th>Subcapitulo</th>
                <th>Apu</th>
                <th>Costo</th>
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>
              {space.map((rooms) => {
                return (
                  <tr key={rooms.apu_id}>
                    <td>{rooms.apu_id}</td>
                    <td>{rooms.capitulo}</td>
                    <td>{rooms.subcapitulo}</td>
                    <td>{rooms.apu}</td>
                    <td>$ {rooms.costo}</td>
                    <td className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#modalUpdate"
                        onClick={() => selectApu(rooms)}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                        Editar 
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div
          className="modal modal fade"
          id="modalUpdate"
          aria-labelledby="modalUpdateLabel"
          aria-hidden="true"
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <div className="heading modal-title">
              <h3>Editar costo de APU</h3>
            </div>

            <div className="modal-body">
              <div className="modal-content form-group text-primary p-3">
                <label>ID</label>
                <input
                  className="form-control"
                  readOnly
                  type="text"
                  name="id"
                  value={apuSelected && apuSelected.apu_id}
                />
                <br />

                <label>Capitulo</label>
                <input
                  className="form-control"
                  readOnly
                  type="text"
                  name="capitulo"
                  value={apuSelected && apuSelected.capitulo}
                />
                <br />

                <label>Subcapitulo</label>
                <input
                  className="form-control"
                  readOnly
                  type="text"
                  name="subcapitulo"
                  value={apuSelected && apuSelected.subcapitulo}
                />
                <br />

                <label>Apu</label>
                <input
                  className="form-control"
                  readOnly
                  type="text"
                  name="apu"
                  value={apuSelected && apuSelected.apu}
                />
                <br />

                <label>Costo</label>
                <input
                  className="form-control"
                  type="number"
                  name="costo"
                  value={apuSelected && apuSelected.costo}
                  onChange={handleChange}
                />
                <br />

                <div className="modal-footer">
                  <button
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={() => edit()}
                  >
                    Actualizar
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center p-3">
        <Link to={-1} type="button" className="btn btn-light btn-lg">
          Volver
        </Link>
      </div>
    </div>
  );
}
