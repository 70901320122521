import React, { useContext, useEffect, useState } from "react";
import { ApartmentContext } from "../../context/ApartmentContext/ApartmentContext";
import './apus_references_styles/index.scss';
import DataService from "../../services/data-service";
import TotalGlobal from "./TotalGlobal";
import { UserContext } from "../../context/UserContext/UserContext";
import { useCalendarLink } from "../../hooks/useCalendarLink";
import lina from '../../assets/images/lina-profile.jpeg'

import { ProfileCard } from "../../components/ProfileCard/ProfileCard";




const CotizadorDesktop = (props) => {
  const [apartment, setApartment] = useContext(ApartmentContext);
  const [user] = useContext(UserContext)
  const isInBogota = localStorage.getItem('city')

  const apusDelCuarto = props.apusDelCuarto ? props.apusDelCuarto.map(apu => {
    return (
      {
        ...apu,
        superApu: apu.superApu.replace('(', '').replace(')', '').replace('/', '').split(' ').join('-'),
      }
    )

  }) : '';

  useEffect(() => {
    setApartment({ ...apartment, total: props.total });
  }, [props.total])

  const addComent = (e) => {
    setApartment({ ...apartment, [e.target.name]: e.target.value });
  };

  const deleteComment = (e) => {
    setApartment({ ...apartment, [e.target.name]: "" });
  };


  const [imgSrc, setImgSrc] = useState([])

  const dataService = new DataService()

  useEffect(() => {
    dataService.getAWSurl(user.projectName, props.style.toUpperCase())
      .then(data => setImgSrc(data))
  }, [user.projectName, props.style])

  const { calendarLink } = useCalendarLink()

  return (
    <>
      <div className="row justify-content-center" >
        <div className="col-11 mt-4">
          <select className="style-selector" onChange={props.changeStyle}>
            <option defaultChecked value={apartment.estilo}>Ver otro estilo</option>
            <option value={"NORDIC"}>Nordic</option>
            <option value={"MINIMAL"}>Minimal</option>
            <option value={"BOHO"}>Boho</option>
          </select>
          <div className="row justify-content-center pt-2">

            <div className="col-12 col-xxl-8 d-flex justify-content-center mt-2" style={{ width: "100%", height: "50vh", boxSizing: "border-box" }}>
              <div className="position-relative w-100 h-100">
                <div id="carouselExampleIndicators" className="carousel slide h-100 cotizador-carousel" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 3"></button>
                  </div>
                  <div className="carousel-inner h-100" >
                    <div className="carousel-item active" data-bs-interval="4000">
                      <img src={imgSrc[0]} className=" img-fluid h-100 w-100 object-fit-contain" alt={props.roomOpen} />
                    </div>
                    <div className="carousel-item" data-bs-interval="4000">
                      <img src={imgSrc[1]} className=" img-fluid h-100 w-100" alt={props.roomOpen} />
                    </div>
                    <div className="carousel-item" data-bs-interval="4000">
                      <img src={imgSrc[2]} className=" img-fluid h-100 w-100" alt={props.roomOpen} />
                    </div>
                    <div className="carousel-item" data-bs-interval="4000">
                      <img src={imgSrc[3]} className=" img-fluid h-100 w-100" alt={props.roomOpen} />
                    </div>
                    <div className="carousel-item" data-bs-interval="4000">
                      <img src={imgSrc[4]} className=" img-fluid h-100 w-100" alt={props.roomOpen} />
                    </div>
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-8 mt-4 mb-4  d-flex justify-content-around">
          <TotalGlobal total={props.total} />
        </div>
        {
          isInBogota
          &&
          <div className="col-8 d-flex flex-column justify-content-around bg-primary rounded gap-4" style={{padding: '30px 80px'}}>
            <div className="d-flex align-items-center justify-content-center gap-3">
              <div className="col-6 overflow-hidden" style={{ borderRadius: '100%', width: '76px', height: '76px' }}>
                <img src={lina} alt="Richie" className="w-100" />
              </div>
              <h5 className=" text-center text-secondary m-0  ">Personaliza tu cotización gratis</h5>
            </div>
            <a href={'https://calendly.com/d/47z-knd-tw7/intro-a-vivvidero'} target="_blank" rel="noreferrer" className="bg-secondary btn ">
              Agendar una llamada
            </a>
            {/* <div className="d-flex justify-content-around align-items-center col-10">

              <ProfileCard image={lina} url={'https://calendly.com/d/47z-knd-tw7/intro-a-vivvidero'} name={'Asesor'} />

            </div> */}
          </div>
        }


      </div>
    </>
  );
};

export default CotizadorDesktop;
