import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApartmentContext } from '../../context/ApartmentContext/ApartmentContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import logo from '../../assets/images/logoVivvidero-verde.png';
import "./_bañosHabitaciones.scss"
import DataService from '../../services/data-service';
import { UserContext } from '../../context/UserContext/UserContext';
import { ImageWithTag } from '../../components';
import { Header } from '../../Layout';

export const BañosHabitaciones = () => {
    const [area] = useLocalStorage('area');
    const [numBaños, setNumBaños] = useLocalStorage('numBaños', 1);
    const [numHabitaciones, setNumHabitaciones] = useLocalStorage('numHabitaciones', 1);
    const [apartment, setApartment] = useContext(ApartmentContext);
    const userContext = useContext(UserContext)
    const [projectId] = useLocalStorage("projectId", "1");

    const navigate = useNavigate();
    const service = new DataService();

    const handleSubmit = e => {
        e.preventDefault();
        service.numBañosSave(numBaños, numHabitaciones)
            .then(() => navigate('/cotizador'))
            .catch(err => console.log(err));
    };

    const checkArea = () => {
        const bañosLimit = area < 40 ? 1 : 2;
        const habitacionesLimit = area < 50 ? 2 : 3;
        const messageHab = area < 40 ? `Para apartamentos menores a 40Mts2, el maximo de habitaciones es ${habitacionesLimit}` : '';
        const messageBanos = area < 40 ? `Para apartamentos menores a 40Mts2, el maximo de baños es ${bañosLimit}` : '';
        return { bañosLimit, habitacionesLimit, messageHab, messageBanos };
    }

    return (
        <section className="col-12  min-vh-100 d-flex flex-column justify-content-around align-items-center py-5 bg-tertiary">
            <Header logoColor={logo} />
            <div className='row d-flex justify-content-evenly align-items-center custom-project'>
                <form onSubmit={handleSubmit} className='col-12 col-md-4 d-flex flex-column justify-content-around align-items-center p-3' style={{ color: '#203F3C' }}>
                    <div className='w-100'>
                        <h3 className="text-start">¿Cuántos baños tiene tu vivienda?</h3>
                        <input
                            name='baños'
                            type="number"
                            placeholder="N° de baños"
                            min={1}
                            max={checkArea().bañosLimit}
                            className="form-control form-control-lg "
                            value={numBaños}
                            onChange={e => {
                                setApartment({ ...apartment, [e.target.name]: (parseInt(e.target.value)) })
                                setNumBaños(e.target.value)
                            }}
                            required
                            onInvalid={e => e.target.setCustomValidity(checkArea().messageBanos)}
                            onInput={e => e.target.setCustomValidity("")}
                        />
                    </div>

                    <div className='w-100'>
                        <h3 className="text-start">¿Cuántas habitaciones tiene tu vivienda?</h3>

                        <input
                            name='habitaciones'
                            type="number"
                            placeholder="N° de habitaciones"
                            min={1}
                            max={checkArea().habitacionesLimit}
                            className="form-control form-control-lg "
                            value={numHabitaciones}
                            onChange={e => {
                                setApartment({ ...apartment, [e.target.name]: (parseInt(e.target.value)) })
                                setNumHabitaciones(e.target.value)
                            }}
                            required
                            onInvalid={e => e.target.setCustomValidity(checkArea().messageHab)}
                            onInput={e => e.target.setCustomValidity("")}
                        />
                    </div>


                    <div className='w-100 d-flex justify-content-evenly m-3'>

                        <button type="submit" className="btn btn-primary btn-lg w-100">Continuar</button>
                    </div>
                </form>
                {!userContext[2] &&
                    <ImageWithTag />}
            </div>
        </section>

    )
}