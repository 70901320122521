import React from 'react'
import { Link } from 'react-router-dom'

const NotAllowedPage = () => {
  return (
    <div className='min-vh-100 d-flex flex-column justify-content-center align-items-center p-1'>
      <h2 className='text-center'>Ups! Parece que no tienes los permisos para ingresar</h2>
      <Link to='/welcome' className='fs-1'>Volver al inicio</Link>
    </div>

  )
}

export default NotAllowedPage