import { useState } from 'react';

const useToggleTrueFalse = () => {
    const [state, setState] = useState(false);

    const toggleTrue = () => setState(true);
    const toggleFalse = () => setState(false);

  return { state, toggleTrue, toggleFalse };
}

export default useToggleTrueFalse;