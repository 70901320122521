import {  useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import { DoYouKnowScreen } from "./components/DoYouKnowScreen/DoYouKnowScreen";
import KitchenTypology from "./components/KitchenTypology/KitchenTypology";
import NotAllowedPage from "./components/NotAllowed/NotAllowedPage";
import ApartmentContextProvider from "./context/ApartmentContext/ApartmentContext";
import UserContextProvider from "./context/UserContext/UserContext";
import UserSelectionContextProvider from "./context/UserContext/UserSelectionContext";
import {
  ProtectedRoutes,
  ArchitectsProtectedRoutes,
} from "./protectedRoutes/protected-routes";
import {  FormularioInicial, CustomProject, Projects, AllLeadsRenovView, AllLeadsView, LoginArchitect, MainView, KnownProjects, UpdatePricesTable, WhatsappLeadsScreen, Cotizador, AllRoomsArquitects, Catalogos, BañosHabitaciones } from "./pages";
import LoadingContextProvider from "./context/LoadingContext/LoadingContext";



function App() {
  const [user, setUser] = useState(null);
  /* useEffect(() => {
    getUser();
  }, []);
  const service = new DataService();

  const getUser = () => {
    if (user === null) {
      service
        .loggedIn()
        .then((response) => {
          setUser(response.user);
        })
        .catch((error) => {
          setUser(false);
          console.log(error);
        });
    }
  }; */

  const updateUser = (userObj) => {
    setUser(userObj);
  };

  return (
    <div className="App ">
      <div className="row justify-content-start w-100 ">
        <ApartmentContextProvider>
          <UserSelectionContextProvider>
            <UserContextProvider>
              <LoadingContextProvider>
                {user ? (
                  <Routes>
                    <Route path="/" element={<Navigate to={'/welcome'} />} />
                    <Route path="/welcome" element={<FormularioInicial updateUser={updateUser} />} />
                    <Route path="/welcome/:pauta" element={<FormularioInicial updateUser={updateUser} />} />
                    <Route path="/architects" element={<LoginArchitect updateUser={updateUser} />} />
                    <Route path="/do-you-know" element={<ProtectedRoutes theUser={user}>  <DoYouKnowScreen /> </ProtectedRoutes>} />
                    <Route path="/proyects" element={<ProtectedRoutes theUser={user}>  <Projects />  </ProtectedRoutes>} />
                    <Route path="/catalog" element={<ProtectedRoutes theUser={user}>  <Catalogos />  </ProtectedRoutes>} />
                    <Route path="/personal-project" element={<ProtectedRoutes theUser={user}> <CustomProject />  </ProtectedRoutes>} />
                    <Route path="/banos-habitaciones" element={<ProtectedRoutes theUser={user}>  <BañosHabitaciones /> </ProtectedRoutes>} />
                    <Route path="/kitchenTypology" element={<ProtectedRoutes theUser={user}>  <KitchenTypology /> </ProtectedRoutes>} />
                    <Route path="/cotizador" element={<ProtectedRoutes theUser={user}>   <Cotizador theUser={user} /> </ProtectedRoutes>} />
                    <Route path="/architects" element={<LoginArchitect />} />
                    <Route path="/main-view" element={<ArchitectsProtectedRoutes theUser={user}> <MainView /> </ArchitectsProtectedRoutes>} />
                    <Route path="/update-prices" element={<ArchitectsProtectedRoutes theUser={user}> <AllRoomsArquitects /> </ArchitectsProtectedRoutes>} />
                    <Route path="/update-prices/:id" element={<ArchitectsProtectedRoutes theUser={user}><UpdatePricesTable /> </ArchitectsProtectedRoutes>} />
                    <Route path="/view-leads" element={<ArchitectsProtectedRoutes theUser={user}>  <AllLeadsView />  </ArchitectsProtectedRoutes>} />
                    <Route path="/view-leads-renovadora" element={<ArchitectsProtectedRoutes theUser={user}>  <AllLeadsRenovView />  </ArchitectsProtectedRoutes>} />
                    <Route path="/known-projects" element={<ArchitectsProtectedRoutes theUser={user}> <KnownProjects /> </ArchitectsProtectedRoutes>} />
                    <Route path="/whatsapp-leads" element={<ArchitectsProtectedRoutes theUser={user}>  <WhatsappLeadsScreen />  </ArchitectsProtectedRoutes>} />
                  </Routes>
                ) : (
                  <Routes>
                    <Route path="/" element={<Navigate to={'/welcome'} />}/>
                    <Route path="/welcome" element={<FormularioInicial updateUser={updateUser} />} />
                    <Route path="/welcome/:pauta" element={<FormularioInicial updateUser={updateUser} />} />
                    <Route path="/architects" element={<LoginArchitect updateUser={updateUser} />} />
                    <Route path="*" element={<NotAllowedPage />} />
                  </Routes>
                )}
              </LoadingContextProvider>
            </UserContextProvider>
          </UserSelectionContextProvider>
        </ApartmentContextProvider>
      </div>
    </div>
  );
}

export default App;
