import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
/* import ReCAPTCHA from "react-google-recaptcha";
 */import { UserContext } from "../../context/UserContext/UserContext";
import DataService from "../../services/data-service";
import useLocalStorage from "../../hooks/useLocalStorage";
import "./_formularioInicial.scss"
import logo from "../../assets/images/logoVivvidero-verde.png"
import imgCarrousel1 from '../../assets/images/cliente-1.png'
/* import imgCarrousel2 from '../../assets/images/cliente-2.png'
import imgCarrousel3 from '../../assets/images/cliente-3.jpg'
import { Carousel } from "../../components"; */
import { validateUtm } from "../../utils/validateUtm";
import { Countdown } from "../../components/FormularioInicial/CountDown";
/* import CarouselSkeleton from "../../components/UI/CarouselSkeleton";
 */


export const FormularioInicial = props => {
  const [user, setUser, isMobile] = useContext(UserContext);
  /*   const [captcha, setCaptcha] = useState("");
    const [isVerified, setIsVerified] = useState(false); */
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [name, setName] = useLocalStorage("name", "");
  const [email, setEmail] = useLocalStorage("email", "");
  const [celular, setCelular] = useLocalStorage("celular", "");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(null);
  const [errMessage, setErrMessage] = useState("")
  const [loading, setLoading] = useState(false)
  let { pauta } = useParams();
  const [isCounting, setIsCounting] = useLocalStorage('isCounting', 'true');

  const service = new DataService();
  /* 
    const handleCaptcha = (value) => {
      setCaptcha(value);
      setBtnDisabled(false);
    }; */

  /* const getCaptcha = () => {
    service.reCaptcha().then((data) => {
      setIsVerified(data.success);
    });
  }; */

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const location = useLocation();
  const { utmSource, utmMedium, utmCampaign, utmContent, utmTerm, product } = validateUtm(location.search);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    setBtnDisabled(true)


    service
      .login(name, email, celular, pauta, utmSource ? utmSource : 'desconocida_cotibot', utmMedium ? utmMedium : 'orgánico', utmCampaign, utmContent, utmTerm, product)
      .then(response => {
        setIsValidPhoneNumber(true)
        props.updateUser(response.user);
        setUser({ ...user, googleSheetId: response?.googleSheetId })
        if(isCounting === 'true') {
          localStorage.setItem("gotDiscount", 'true')
        }
        navigate("/proyects");
      })
      .catch((err) => {
        setLoading(false)
        setBtnDisabled(false)
        setErrMessage(err.response.data.message)
        setIsValidPhoneNumber(false)
        console.log(err)
      })
  };

  useEffect(() => {
    setName(user.name);
    localStorage.removeItem('gotDiscount')
  }, [user]);

  return (
    <section className="formInicialScreen bg-tertiary">
      <Countdown />
      {/* {
        !isMobile &&
        <div className="col-6">
          <img src={logo} alt="logo" className="" />
        </div>
      } */}

      <div className="form-and-carrusel">
        {/* <Suspense fallback={<CarouselSkeleton />}>
          <Carousel imgCarrousel1={imgCarrousel1} imgCarrousel2={imgCarrousel2} imgCarrousel3={imgCarrousel3} />
        </Suspense> */}
        {/* <Carousel imgCarrousel1={imgCarrousel1} imgCarrousel2={imgCarrousel2} imgCarrousel3={imgCarrousel3} /> */}
        <div className="col-12 col-md-5 position-relative">
          <img src={imgCarrousel1} alt="logo" className="home-image" />
          <div className="overlay-home-image">
            <h1 className="text-white text-center">Cotiza los acabados de tu vivienda</h1>
          </div>
        </div>
        
        <form onSubmit={handleSubmit} className="col-12 col-md-4  d-flex flex-column justify-content-between align-items-center position-relative p-3 px-5 px-md-0"  >
          {
            !isMobile &&
            <div className="d-flex flex-column gap-4 align-items-center">
            <img src={logo} alt="logo" className="col-5 mb-5" />
              <h1 className="text-primary text-center ">Cotiza los acabados <br/> de tu vivienda</h1>
            </div>
          }
          <h2 className="text-center text-primary mb-3"  >
            Gratis y en 5 minutos
          </h2>

          <input
            type="text"
            name="name"
            placeholder="Nombre"
            className="form-control form-control-lg "
            value={user.name}
            onChange={handleInputChange}
            required
          />

          <input
            type="email"
            name="email"
            placeholder="Correo"
            className="form-control form-control-lg "
            value={email}
            onChange={e => setEmail(e.target.value)}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            title="The email must contain an @ symbol, a domain name, and a dot"
            required
          />

          <input
            type="tel"
            name="phoneNumber"
            placeholder="Celular"
            className={`form-control form-control-lg  ${isValidPhoneNumber === false ? 'is-invalid' : ''}`}
            value={celular}
            onChange={e => {
              setCelular(e.target.value.trim())
              setIsValidPhoneNumber(null);
            }}
            minLength={10}
            title="Please enter a valid Colombian phone number"
            required
          />
          {
            isValidPhoneNumber === false
            &&
            <div className="invalid-feedback w-50">
              {errMessage}
            </div>
          }
          {/* <div
            id="form"
            className="col-12 col-md-4 d-flex flex-column justify-content-around align-items-center position-relative"
            onSubmit={getCaptcha}
          >
            <ReCAPTCHA
              sitekey="6LeZOOcgAAAAACtlGyYXoBE_cbVgMaexBwWkOurM"
              onChange={handleCaptcha}
            />
          </div> */}

          <button
            type="submit"
            className="btn btn-primary btn-lg shadow w-100 my-3 py-3"
            disabled={btnDisabled}
          >
            {loading && <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>}
            Comenzar
          </button>
        </form>
      </div>

    </section>
  );
};
