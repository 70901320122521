import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import DataService from "../../../../services/data-service";
import { ButtonLoading } from "../../../../components";

export const DeleteProject = ({ project, request, setRequest }) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const dataService = new DataService();

  const handleDelete = () => {
    setBtnLoading(true);
    window.confirm("¿Está seguro de eliminar este proyecto?") &&
      dataService
        .deleteProject(project.id)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          alert("Proyecto eliminado correctamente");
          setBtnLoading(false);
          setRequest(!request);
        });
  };

  return (
    <>
      {btnLoading ? (
        <ButtonLoading />
      ) : (
        <button
          className="btn btn-secondary m-1 hoverBtn"
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      )}
    </>
  );
};
