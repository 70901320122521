import React from 'react'
import logoGreen from '../../assets/images/logoVivvidero-verde.png'
import logoWhite from '../../assets/images/logoVivvidero-blanco.png'

export const Header = ({ logoColor, size }) => {

    return (
        
            <div className="d-flex justify-content-center align-items-center" style={{width: size}}>

                <img src={logoColor === "white" ? logoWhite : logoGreen} alt="vivvidero" className="w-100" />

            </div>
        
    )
}
 