import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartFlatbedSuitcase } from "@fortawesome/free-solid-svg-icons";

export const ModalApusLeads = ({ lead }) => {
  return (
    <div >
      <button
        type="button"
        className="btn btn-primary w-100 h-100 mb-1 my-2 d-flex align-items-center justify-content-center hoverBtn"
        data-bs-toggle="modal"
        data-bs-target={`#modal${lead.id}`}
      >
        <FontAwesomeIcon icon={faCartFlatbedSuitcase} />
        <p className="mx-1 my-0">Detalles</p>
      </button>
      <div
        className="modal fade"
        id={`modal${lead.id}`}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <div className="table-responsive w-100">
                <table className="table table-primary table-hover ">
                  <thead>
                    <tr className="table-secondary">
                      <th scope="col">Baño Principal</th>
                      <th scope="col">Habitación Principal</th>
                      <th scope="col">Cocina</th>
                      <th scope="col">Sala / Comedor</th>
                      <th scope="col">Cuarto Aseo</th>
                      {lead.n_baños == 2 && (
                        <th scope="col">Baño secundario</th>
                      )}
                      {lead.n_habitaciones >= 2 && (
                        <th scope="col">Habitación secundaria</th>
                      )}
                      {lead.n_habitaciones == 3 && (
                        <th scope="col">Habitación terciaria</th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="modal-body">
                    <tr>
                      <td>
                        <p>
                          Subtotal: $
                          {lead.jdata["Baño principal"].reduce(
                            (acc, curr) => acc + curr.costo,
                            0
                          )}{" "}
                        </p>
                        <ul>
                          {lead.jdata["Baño principal"].map((apu) => (
                            <li key={apu.superApu}> {apu.nombre} </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <p>
                          Subtotal: $
                          {lead.jdata["Habitación principal"].reduce(
                            (acc, curr) => acc + curr.costo,
                            0
                          )}{" "}
                        </p>
                        <ul>
                          {lead.jdata["Habitación principal"].map((apu) => (
                            <li key={apu.superApu}> {apu.nombre} </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <p>
                          Subtotal: $
                          {lead.jdata["Cocina"].reduce(
                            (acc, curr) => acc + curr.costo,
                            0
                          )}{" "}
                        </p>
                        <ul>
                          {lead.jdata["Cocina"].map((apu) => (
                            <li key={apu.superApu}> {apu.nombre} </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <p>
                          Subtotal: $
                          {lead.jdata["Sala / Comedor"].reduce(
                            (acc, curr) => acc + curr.costo,
                            0
                          )}{" "}
                        </p>
                        <ul>
                          {lead.jdata["Sala / Comedor"].map((apu) => (
                            <li key={apu.superApu}> {apu.nombre} </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <p>
                          Subtotal: $
                          {lead.jdata["Cuarto aseo"].reduce(
                            (acc, curr) => acc + curr.costo,
                            0
                          )}{" "}
                        </p>
                        <ul>
                          {lead.jdata["Cuarto aseo"].map((apu) => (
                            <li key={apu.superApu}> {apu.nombre} </li>
                          ))}
                        </ul>
                      </td>
                      {lead.n_baños == 2 && (
                        <td>
                          <p>
                            Subtotal: $
                            {lead.jdata["Baño secundario"]?.reduce(
                              (acc, curr) => acc + curr.costo,
                              0
                            )}{" "}
                          </p>
                          <ul>
                            {lead.jdata["Baño secundario"]?.map((apu) => (
                              <li key={apu.superApu}> {apu.nombre} </li>
                            ))}
                          </ul>
                        </td>
                      )}
                      {lead.n_habitaciones >= 2 && (
                        <td>
                          <p>
                            Subtotal: $
                            {lead.jdata["Habitación secundaria"]?.reduce(
                              (acc, curr) => acc + curr.costo,
                              0
                            )}{" "}
                          </p>
                          <ul>
                            {lead.jdata["Habitación secundaria"]?.map((apu) => (
                              <li key={apu.superApu}> {apu.nombre} </li>
                            ))}
                          </ul>
                        </td>
                      )}
                      {lead.n_habitaciones == 3 && (
                        <td>
                          <p>
                            Subtotal: $
                            {lead.jdata["Habitación terciaria"]?.reduce(
                              (acc, curr) => acc + curr.costo,
                              0
                            )}{" "}
                          </p>
                          <ul>
                            {lead.jdata["Habitación terciaria"]?.map((apu) => (
                              <li key={apu.superApu}> {apu.nombre} </li>
                            ))}
                          </ul>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
