import { useEffect, useState } from 'react'

export const useCalendarLink = () => {

    const [calendarLink, setCalendarLink] = useState('https://calendly.com/cotibot/marketing-ventas')

    useEffect(() => {
        let random = Math.floor(Math.random() * 3) + 1;
        setCalendarLink(random === 1 ? 'https://calendly.com/cotibot/marketing-ventas' : random === 2 ? 'https://calendar.app.google/uJbdCP3Y6tb7ZpgW8' : 'https://calendar.app.google/oEHMuhAoAedgJf789')
    }, [])





    return { calendarLink }
}
