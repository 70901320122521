import React, { useState, useEffect } from 'react';
import './_countbox.scss';

export const Countdown = () => {

    const [timeLeft, setTimeLeft] = useState(localStorage.getItem('timeLeft'));
    const [isCounting, setIsCounting] = useState(true);

    // Persistir el estado en localStorage
    useEffect(() => {

        const isCounting = localStorage.getItem('isCounting');
        const timeLeftStorage = localStorage.getItem('timeLeft');
        
        if (timeLeftStorage === 'null' || timeLeftStorage === null) {
            setTimeLeft(300);
            localStorage.setItem('timeLeft', 300);
            localStorage.setItem('isCounting', true);
            return;
        }

        if (isCounting === 'false') {
            return;
        }

        localStorage.setItem('timeLeft', timeLeft);
        localStorage.setItem('isCounting', isCounting);
    }, [timeLeft, isCounting]);


    useEffect(() => {
        let interval = null;
        if (isCounting && timeLeft > 0) {
            interval = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            setIsCounting(false);
            localStorage.setItem('isCounting', false);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isCounting, timeLeft]);    

    return (
        <>
            {
                timeLeft && timeLeft > 0 ?
                    (
                        <div className="banner-descuento text-primary">
                            <p>
                                🎉 Desbloquea Bono de descuento
                            </p>
                            <div className='d-flex items-center gap-2'>
                                <div className='count-box'>0</div>
                                <div className='count-box'>{Math.floor(timeLeft / 60)}
                                </div>
                                <div>:</div>
                                <div className='count-box'>{/* {timeLeft % 60 < 10 ? '0' : ''} */}{(timeLeft % 60).toString()[1] ? (timeLeft % 60).toString()[0] : '0'}</div>
                                <div className='count-box'>{/* {timeLeft % 60 < 10 ? '0' : ''} */}{(timeLeft % 60).toString()[1] ? (timeLeft % 60).toString()[1] : (timeLeft % 60).toString()[0]}</div>
                            </div>
                        </div>
                    )
                    :
                    null
            }
        </>
    );
};
