import React, { useContext } from 'react'
import logo from "../../assets/images/logoVivvidero-verde.png"
import nordic from "../../assets/images/nordic.png"
import minimal from "../../assets/images/minimal.png"
import boho from "../../assets/images/boho.png"
import { ApartmentContext } from '../../context/ApartmentContext/ApartmentContext'
import { useNavigate } from 'react-router-dom'
import "./_catalogos.scss"
import { UserContext } from '../../context/UserContext/UserContext'
import { SingleCatalogue } from '../../components'

export const Catalogos = () => {
    const userContext = useContext(UserContext)
    const [apartment, setApartment] = useContext(ApartmentContext);
    const navigate = useNavigate();

    const handleSubmit = (style) => {
        setApartment({ ...apartment, estilo: style });
        navigate("/cotizador")
    }

    return (
        <section className="col-12 d-flex flex-column align-items-center justify-content-between justify-content-md-evenly px-3 min-vh-100 bg-tertiary">
            <div className="p-3">
                <img
                    src={logo}
                    alt="vivvidero brand"
                    className="mt-3 mt-md-0 w-100"
                />
            </div>
            <h2 className='text-primary text-center px-5'>Elige tu catálogo de diseño favorito</h2>

            {userContext[2]
                ?
                <div id="carouselExample" className="carousel carousel-dark slide w-100 carousel-catalog" data-bs-touch="true" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="4000">
                            <SingleCatalogue estilo={nordic} titulo={"Nordic"} descripcion={"Austero, simple y natural"} submit={handleSubmit} />
                        </div>
                        <div className="carousel-item" data-bs-interval="4000">
                            <SingleCatalogue estilo={minimal} titulo={"Minimal"} descripcion={"Elegante, sofisticado y contrastante"} submit={handleSubmit} />                    </div>
                        <div className="carousel-item" data-bs-interval="4000">
                            <SingleCatalogue estilo={boho} titulo={"Boho"} descripcion={"Vibrante, auténtico y colorido"} submit={handleSubmit} />
                        </div>
                    </div>
                    <button className="carousel-control-prev arrow-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next arrow-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                :
                <div className='d-flex col-10 justify-content-evenly align-items-center'>
                    <SingleCatalogue estilo={nordic} titulo={"Nordic"} descripcion={"Austero, simple y natural"} submit={handleSubmit} />
                    <SingleCatalogue estilo={minimal} titulo={"Minimal"} descripcion={"Elegante, sofisticado y contrastante"} submit={handleSubmit} />
                    <SingleCatalogue estilo={boho} titulo={"Boho"} descripcion={"Vibrante, auténtico y colorido"} submit={handleSubmit} />
                </div>
            }
        </section>
    )
}
