import React, { useState } from 'react';
import './total-globals.scss'
import open_eye from "../../assets/icons/open_eye.png"
import close_eye from "../../assets/icons/Hide.png"
import { convertWithCommas } from '../../utils/helpers/convertWithCommas';


const TotalGlobal = props => {

    const [showPrices, setShowPrices] = useState({
        obra: true,
        materiales: false
    })



    return (

        <div className="row col-12 ">
            <div className='border rounded p-3 total-globals my-2'>

                <div className='row text-primary fw-bold align-items-center totals'>
                    <div className='col-7 d-flex  align-items-center'>
                        <div role="button" onClick={() => setShowPrices({ ...showPrices, "obra": !showPrices.obra })}>
                            <img src={`${showPrices.obra ? open_eye : close_eye}`} alt="open-eye" />
                        </div>
                        <h4 className='fw-bold m-0 mx-2'> Obra Completa</h4>
                    </div>
                    <div className=' col-5 prices-container d-flex justify-content-between align-items-center'>
                        {showPrices.obra &&
                            <>
                                <div>
                                    <p className=' my-0'>Entre:</p>
                                </div>
                                <div className=' fw-bold prices'>
                                    <p className='m-0'>${convertWithCommas((0.85 * props.total).toFixed(0))}M</p>
                                    <p className='m-0'>${convertWithCommas((1.15 * props.total).toFixed(0))}M</p>
                                </div>
                            </>
                        }
                    </div>
                </div>
                {/* OCULTAMOS EL PRECIO DE "SOLO MATERIALES" POR NO VENDERLO MAS  */ }
                {/* <hr className='border border-primary' />
                <div className='row text-primary fw-bold align-items-center totals'>
                    <div className='col-7 '>
                        <div className='d-flex'>
                            <div role="button" onClick={() => setShowPrices({ ...showPrices, "materiales": !showPrices.materiales })}>
                                <img src={`${showPrices.materiales ? open_eye : close_eye}`} alt="open-eye" />
                            </div>
                            <h4 className='mx-2 fw-bold'> Solo materiales</h4>
                        </div>
                    </div>
                    <div className='col-5 prices-container d-flex justify-content-between align-items-center'>

                        {showPrices.materiales &&
                            <>
                                <div>
                                    <p className='my-0' >Entre:</p>
                                </div>
                                <div className=' fw-bold prices'>
                                    <p className='m-0'>${convertWithCommas((0.65 * (0.85 * props.total)).toFixed(0))}M</p>

                                    <p className='m-0'>${convertWithCommas((0.65 * (1.15 * props.total)).toFixed(0))}M</p>
                                </div>
                            </>
                        }

                    </div>
                </div> */}
            </div>
        </div>

    )
}

export default TotalGlobal