import React, { useState } from "react";
import "./architect-container.scss";
import { Link, useNavigate } from "react-router-dom";

import vivvidero_white from "../../images/vivvidero 4  - copia 1.png";
import { arquitectsLogin } from "../../utils/arquitectsLogin";
import { ButtonLoading } from "../../components";

export const LoginArchitect = ({ updateUser }) => {
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const history = useNavigate();

  const handleInputChange = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const submitForm = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const arcLogued = await arquitectsLogin(user);
    if (arcLogued.ok) {
      alert(arcLogued.message);
      history("/main-view");
      updateUser(arcLogued.user);
      console.log(arcLogued.user);
    } else {
      alert(arcLogued.message);
      setBtnLoading(false);
    }
  };

  return (
    <form
      onSubmit={submitForm}
      className="col-12 col-md-5 offset-md-3 d-flex flex-column justify-content-around align-items-center"
      style={{ minHeight: "600px" }}
    >
      <img
        src={vivvidero_white}
        alt="vivvidero brand"
        width="180px"
        className="mt-3"
      />

      <input
        type="text"
        name="username"
        placeholder="Nombre Completo"
        className="form-control form-control-lg w-50 m-3 bg-secondary"
        value={user.name}
        onChange={handleInputChange}
        required
      />

      <input
        type="password"
        name="password"
        placeholder="Contraseña"
        className="form-control form-control-lg w-50 m-3 bg-secondary"
        value={user.email}
        onChange={handleInputChange}
        required
      />
      {btnLoading ? (
        <ButtonLoading />
      ) : (
        <button
          type="submit"
          className="btn btn-primary w-50 btn-lg m-3 shadow"
        >
          Log in
        </button>
      )}
      <Link to={"/welcome"} type="button" className="btn btn-light btn-lg">
        Volver
      </Link>
    </form>
  );
};

