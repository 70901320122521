import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApartmentContext } from "../../context/ApartmentContext/ApartmentContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import vivvidero_brand_green from "../../images/cubo central verde.png";

export const DoYouKnowScreen = () => {
  const [apartment, setApartment] = useContext(ApartmentContext);
  // const [isProject, setIsProject] = useLocalStorage('isProyect', 'false');
  const history = useNavigate();

  const handleKnowProyect = (e) => {
    if (e.target.value === "si") {
      setApartment({
        ...apartment,
        isProyect: true,
      });
      // setIsProject('true');
      history("/proyects");
      return;
    }

    setApartment({
      ...apartment,
      isProyect: false,
    });
    history("/component3");
  };

  return (
    <div
      className="col-12 col-md-4 d-flex flex-column justify-content-evenly align-items-center"
      style={{ minHeight: "100vh", backgroundColor: "#EBC991" }}
    >
      <img
        src={vivvidero_brand_green}
        alt="vivvidero brand"
        width="120px"
        className="mt-3"
      />

      <p
        className="heading w-75 mt-2 text-center mb-0"
        style={{ color: "#203F3C", fontSize: "2.5rem", lineHeight: "2.5rem" }}
      >
        ¿Conoces el nombre del Proyecto?
      </p>

      <div className="w-75 d-flex justify-content-evenly">
        <button
         
          type="button"
          className="btn btn-primary btn-lg"
          onClick={handleKnowProyect}
          value={"si"}
        >
          Si
        </button>
        <button
          
          type="button"
          className="btn btn-primary btn-lg"
          onClick={handleKnowProyect}
          value={"no"}
        >
          No
        </button>
      </div>
      <Link to={-1} type="button" className="btn btn-light btn-lg">
        Volver
      </Link>
    </div>
  );
};
