import React, { useState } from 'react';

function BannerVivvicredit() {
    const [mostrarBanner, setMostrarBanner] = useState(true);

    const handleCerrarBanner = () => {
        setMostrarBanner(false);
    };

    return (
        <div>
            {mostrarBanner && (
                <div className={`banner-vivvicredit ${mostrarBanner ? '' : 'banner-oculto'}`}>
                    <button
                        className="banner-vivvicredit__close-btn"
                        onClick={handleCerrarBanner}
                    >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2982 12.3797C13.3585 12.44 13.4063 12.5116 13.4389 12.5903C13.4715 12.6691 13.4883 12.7535 13.4883 12.8387C13.4883 12.924 13.4715 13.0084 13.4389 13.0871C13.4063 13.1659 13.3585 13.2374 13.2982 13.2977C13.2379 13.358 13.1664 13.4058 13.0876 13.4384C13.0088 13.471 12.9244 13.4878 12.8392 13.4878C12.754 13.4878 12.6695 13.471 12.5908 13.4384C12.512 13.4058 12.4405 13.358 12.3802 13.2977L7.0005 7.91717L1.62079 13.2977C1.49906 13.4194 1.33395 13.4878 1.1618 13.4878C0.989647 13.4878 0.824545 13.4194 0.702814 13.2977C0.581083 13.176 0.512695 13.0109 0.512695 12.8387C0.512695 12.6666 0.581083 12.5015 0.702814 12.3797L6.08334 7.00001L0.702814 1.6203C0.581083 1.49857 0.512695 1.33347 0.512695 1.16131C0.512695 0.989159 0.581083 0.824056 0.702814 0.702325C0.824545 0.580595 0.989647 0.512207 1.1618 0.512207C1.33395 0.512207 1.49906 0.580595 1.62079 0.702325L7.0005 6.08285L12.3802 0.702325C12.5019 0.580595 12.667 0.512207 12.8392 0.512207C13.0114 0.512207 13.1765 0.580595 13.2982 0.702325C13.4199 0.824056 13.4883 0.989159 13.4883 1.16131C13.4883 1.33347 13.4199 1.49857 13.2982 1.6203L7.91766 7.00001L13.2982 12.3797Z" fill="#F4F2E8" />
                        </svg>
                    </button>
                    <a href='https://info.vivvidero.com/'
                        target='_blank'
                        rel='noreferrer noopener'
                        className={`bg-secondary text-primary p-2`}
                    >
                        Necesitas crédito?
                    </a>
                </div>
            )}
            
        </div>
    );
}

export default BannerVivvicredit;