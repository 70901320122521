import React from "react";

export const ButtonLoading = () => {
  return (
    <button className="btn btn-primary mx-1" type="button" disabled>
      <span
        className="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span className="visually-hidden">Loading...</span>
    </button>
  );
};
