const refactorPorSubcap = (obj, roomName) => {
    const ordenado = obj
        .reduce((acc, dato) => {
            if (!acc.includes(dato.subcapitulo)) {
                acc.push(dato.subcapitulo);
            }
            return acc;
        }, [])
        .map((dato) => {
            return {
                subcapitulo: dato,
                apu: obj
                .filter((objeto) => objeto.subcapitulo === dato)
                .map((objeto) => {
                        return { um: objeto.unidad_de_medida, nombre: objeto.nombre, costo: parseInt(objeto.costo), superApu: objeto.superapu.toString().concat(roomName), subcapitulo: objeto.subcapitulo, preseleccionado: objeto.preseleccionado, id: objeto.id, cantidad: objeto.cantidad, areaDeSector: objeto.areaDeSector };
                    }),
            };
        });
    return ordenado;
};

export default refactorPorSubcap;