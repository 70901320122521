import React from 'react'
import { convertWithCommas } from '../../utils/helpers/convertWithCommas'
import putCommasToNumbers from '../../utils/putCommasToNumbers';
import putDotsToNumbers from '../../utils/putDotsToNumbers';

export const TotalesCanvas = ({ total, packSelected }) => {

    console.log();
    

    return (
        <>
            {/* <h5 className="text-center">Precio estimado</h5> */}
            <div className="d-flex justify-content-between align-items-center ">
                {/* {packSelected === "Basico" ?
                    <p className="w-50"> Obra completa</p>
                    :
                    <p className="w-50"> Materiales</p>
                } */}
                <h5 className="text-center my-1">Total estimado: </h5>
                <p className='my-1'>
                    ${putDotsToNumbers(total)}M <small>COP</small>
                </p>
                {/* <div className="d-flex justify-content-between align-items-center w-50">
                    <div className="">
                        <p>Entre</p>
                    </div>
                    <div className=" d-flex flex-column">
                        {
                            packSelected === "Basico"
                                ?
                                <>
                                    <p>${convertWithCommas((0.85 * total).toFixed(0))}M</p>
                                    <p>${convertWithCommas((1.15 * total).toFixed(0))}M</p>
                                </>
                                :
                                <>
                                    <p>${convertWithCommas((0.65 * (0.85 * total)).toFixed(0))}M</p>
                                    <p>${convertWithCommas((0.65 * (1.15 * total)).toFixed(0))}M</p>
                                </>
                        }
                    </div>
                </div> */}
            </div>
            
        </>

    )
}
