import React, { useContext, useEffect, useMemo, useState } from "react";
import "./_componente-5.scss";
import info from "../../assets/icons/info.png"
import arrow_up from "../../assets/icons/Arrow-up.png"
import arrow_down from "../../assets/icons/Arrow-down.png"
import ResponsiveView from "./ResponsiveView";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext/UserContext";
import { ApartmentContext } from "../../context/ApartmentContext/ApartmentContext";
import DataService from "../../services/data-service";
import refactorPorSubcap from "../../utils/organizeApus";
import useLocalStorage from "../../hooks/useLocalStorage";
import useToggleTrueFalse from "../../hooks/useToggleTrueFalse";
import { UserSelectionContext } from "../../context/UserContext/UserSelectionContext";
import EspaciosDropdown from "./EspaciosDropdown";
import { OffCanvasTotals } from "./OffCanvasTotals";
import CotizadorDesktop from "./CotizadorDesktop";
import { Spinner } from "../../components";
import logo from "../../assets/images/logoVivvidero-verde.png"
import BannerVivvicredit from "./BannerVivvicredit/BannerVivvicredit";
import { DiscountBanner } from "../../components/UI/DiscountBanner";

export const Cotizador = ({ theUser }) => {
  const [numBaños] = useLocalStorage("numBaños");
  const [numHabitaciones] = useLocalStorage("numHabitaciones");
  const [apartmentArea] = useLocalStorage("area");
  const [projectId] = useLocalStorage("projectId");
  const [isProject] = useLocalStorage("conocido");
  const loading = useToggleTrueFalse();
  const [roomOpen, setRoomOpen] = useState("");
  const [apartment, setApartment] = useContext(ApartmentContext);
  const dataService = new DataService();
  const [user, setUser, isMobile] = useContext(UserContext);
  const [apusSelected, setApusSelected] = useContext(UserSelectionContext);
  const [rooms, setRooms] = useState([]);
  const history = useNavigate();
  const [name] = useLocalStorage("name");
  const [email] = useLocalStorage("email");
  const [celular] = useLocalStorage("celular");
  const [projectName] = useLocalStorage("projectName");
  const [startMoment] = useLocalStorage("startMoment");
  const [kitchenTypology] = useLocalStorage("kitchenTypology");
  const [basicPack, setBasicPack] = useState({});
  const [standardPack, setStandardPack] = useState({});
  const [packSelected, setPackSelected] = useState('Básico');
  const [openCanvas, setOpenCanvas] = useState(false);
  const [freezeText, setFreezeText] = useState(false);

  const [isDiscountBanner, setIsDiscountBanner] = useState(true)
  const getDiscount = localStorage.getItem('gotDiscount')


  useEffect(() => {
    activatePremiumOption()
  }, [rooms.length])

  useEffect(() => {
    localStorage.setItem('isCounting', false);
    localStorage.setItem('timeLeft', 0);
  }, [])


  useEffect(() => {

    if (!!numBaños && !!numHabitaciones && !!apartmentArea && isProject !== undefined) {
      getAllRooms(numBaños, numHabitaciones, apartmentArea, projectId, isProject);
    }
  }, [numBaños, numHabitaciones, apartmentArea, projectId, isProject]);

  const getAllRooms = (num1, num2, num3, projectId, isProyect) => {
    loading.toggleTrue();

    dataService
      .getAllRooms(num1, num2, num3, projectId, isProyect)
      .then((rooms) => {
        const refactorAccordingToSubCap = rooms.map((room) => {
          return {
            ...room,
            apus: refactorPorSubcap(room.apus, room.nombre),
          };
        });
        const kitchenObj = refactorAccordingToSubCap.filter(room => room.nombre === 'Cocina');
        const allRoomsWithoutKitchen = refactorAccordingToSubCap.filter(room => room.nombre !== 'Cocina');
        const actualData = [...kitchenObj, ...allRoomsWithoutKitchen];
        setRooms(actualData);
        setApartment({
          ...apartment,
          roomsPicked: actualData,
        });
        setApusSelected({
          ...apusSelected,
          'Baño principal': actualData?.filter(room => room.nombre === 'Baño principal')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
          'Baño secundario': actualData?.filter(room => room.nombre === 'Baño secundario')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
          'Baño terciario': actualData?.filter(room => room.nombre === 'Baño terciario')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
          'Habitación principal': actualData?.filter(room => room.nombre === 'Habitación principal')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
          'Habitación secundaria': actualData?.filter(room => room.nombre === 'Habitación secundaria')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
          'Habitación terciaria': actualData?.filter(room => room.nombre === 'Habitación terciaria')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
          'Sala / Comedor': actualData?.filter(room => room.nombre === 'Sala / Comedor')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
          'Cuarto aseo': actualData?.filter(room => room.nombre === 'Cuarto aseo')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
          Cocina: actualData?.filter(room => room.nombre === 'Cocina')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),

        })
      })
      .finally(() => {
        loading.toggleFalse();
      });
  };


  /* CALCULO DEL TOTAL GLOBAL  */
  const totales = useMemo(() => {
    let total = 0;
    for (let espacio in apusSelected) {
      if (
        apusSelected[espacio] &&
        rooms.filter((room) => room.nombre === espacio)[0]
      ) {
        total += apusSelected[espacio].reduce(
          (acc, curr) => acc + curr.costo,
          0
        );
      }
    }
    return {
      total,
    };
  }, [apusSelected, rooms])



  const changeStyle = e => {
    setApartment({ ...apartment, estilo: e.target.value });
  };

  const reName = (name) => {
    const newName = name.split(" ")[0];
    return newName === "Baño"
      ? "TOILET"
      : newName === "Sala"
        ? "SALA_COMEDOR"
        : newName === "Cuarto"
          ? "ROPAS"
          : newName;
  };


  const handleCotizar = async () => {
    const usuario = {
      name,
      email,
      celular,
      projectName,
      startMoment,
      apartment: user.apartment
    }
    try {
      const lead = { usuario, apusSelected, apartment };
      await dataService.postLeads(lead);

      loading.toggleTrue();
      history("/component8");
    } catch (error) {
      console.log(error);
    }
  };

  const activatePremiumOption = () => {

    setStandardPack({
      'Baño principal': rooms?.filter(room => room.nombre === 'Baño principal')[0]?.apus.map(apu => apu.apu).flat(2),
      'Baño secundario': rooms?.filter(room => room.nombre === 'Baño secundario')[0]?.apus.map(apu => apu.apu).flat(2),
      'Baño terciario': rooms?.filter(room => room.nombre === 'Baño terciario')[0]?.apus.map(apu => apu.apu).flat(2),
      'Habitación principal': rooms?.filter(room => room.nombre === 'Habitación principal')[0]?.apus.map(apu => apu.apu).flat(2),
      'Habitación secundaria': rooms?.filter(room => room.nombre === 'Habitación secundaria')[0]?.apus.map(apu => apu.apu).flat(2),
      'Habitación terciaria': rooms?.filter(room => room.nombre === 'Habitación terciaria')[0]?.apus.map(apu => apu.apu).flat(2),
      'Sala / Comedor': rooms?.filter(room => room.nombre === 'Sala / Comedor')[0]?.apus.map(apu => apu.apu).flat(2),
      'Cuarto aseo': rooms?.filter(room => room.nombre === 'Cuarto aseo')[0]?.apus.map(apu => apu.apu).flat(2),
      Cocina: rooms?.filter(room => room.nombre === 'Cocina')[0]?.apus.map(apu => apu.apu).flat(2),
    });

    setBasicPack({
      'Baño principal': rooms?.filter(room => room.nombre === 'Baño principal')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
      'Baño secundario': rooms?.filter(room => room.nombre === 'Baño secundario')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
      'Baño terciario': rooms?.filter(room => room.nombre === 'Baño terciario')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
      'Habitación principal': rooms?.filter(room => room.nombre === 'Habitación principal')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
      'Habitación secundaria': rooms?.filter(room => room.nombre === 'Habitación secundaria')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
      'Habitación terciaria': rooms?.filter(room => room.nombre === 'Habitación terciaria')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
      'Sala / Comedor': rooms?.filter(room => room.nombre === 'Sala / Comedor')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
      'Cuarto aseo': rooms?.filter(room => room.nombre === 'Cuarto aseo')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
      Cocina: rooms?.filter(room => room.nombre === 'Cocina')[0]?.apus.map(apu => apu.apu).flat(2).filter(apu => apu.preseleccionado),
    });
  };


  const isInBogota = localStorage.getItem('city')
  

  return (
    <>
      {
        isDiscountBanner && getDiscount &&
        <DiscountBanner setIsDiscount={setIsDiscountBanner} />
      }
      <div className={`col-12 row vivvi-font-family p-md-2 ${isDiscountBanner && getDiscount ? 'p-md-5' : 'px-md-5'}  bg-tertiary`} style={{ minHeight: "100vh" }}>
        {isMobile
          ?
          <ResponsiveView roomOpen={roomOpen} style={apartment.estilo} isDiscountBanner={isDiscountBanner} getDiscount={getDiscount} />
          :
          <div className=" col-12 col-md-7 order-md-2 d-md-block d-none  py-md-5">

            <CotizadorDesktop total={totales.total} roomOpen={roomOpen} style={apartment.estilo} setStyle={changeStyle} reName={reName} apusDelCuarto={apusSelected[roomOpen]} kitchenTypology={kitchenTypology} handleCotizar={handleCotizar} changeStyle={changeStyle} />

          </div>
        }
        <BannerVivvicredit />
        <div className="col-12 col-md-5 pb-5 py-md-5" >

          <div className="col-12 px-2 d-none d-md-flex justify-content-center align-items-center mb-5" >
            <img src={logo} alt="logo" style={{ width: "200px" }} />
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex flex-column justify-content-around align-items-start mt-3 d-md-inline-flex d-none text-primary info-estilo">
              <div className="d-flex justify-content-between align-items-center col-12">
                <h3>
                  {apartment.estilo}
                </h3>

              </div>

              <p>El diseño que elijas sin que te cueste más</p>
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <img src={info} alt="info" />
                </div>
                <p className="m-0 mx-2">Selecciona los ítems que desees incluir en cada espacio.</p>
              </div>
            </div>
            <div className="col-11 col-md-12">
              <div className="row justify-content-center">
                <div className="col-12 d-flex flex-column justify-content-evenly align-items-center" style={{ minHeight: "50%", paddingBottom: "80px" }}>
                  {loading.state ? (
                    <Spinner />
                  ) : (
                    rooms.map((sala, idx) => (
                      <EspaciosDropdown key={sala.nombre} globalTotal={totales.total} roomName={sala.nombre} apus={sala.apus} superApu={sala.superapu} changeName={setRoomOpen} openRoom={roomOpen} idx={idx} />
                    ))
                  )}{" "}
                </div>
              </div>
            </div>
            {
              isMobile &&
              <>
                <div className={`d-flex justify-content-center btn-offcanvas ${openCanvas && isInBogota ? 'btn-offcanvas-open' : openCanvas && !isInBogota ?  'IsntInBogota-btn-offcanvas-open' : 'btn-offcanvas-close'} `} onClick={() => {
                  setOpenCanvas(!openCanvas)
                  setFreezeText(true)
                }}>
                  {
                    openCanvas
                      ?
                      <img src={arrow_down} alt=' ' />
                      :
                      <img src={arrow_up} alt=' ' />
                  }
                </div>
                <div className={`bg-primary agendar-videollamada panel ${isInBogota ? 'isInBogotaPanel' : 'IsntInBogotaPanel'} ${openCanvas ? 'open' : ''}`}>
                  <OffCanvasTotals setPackSelected={setPackSelected} openCanvas={openCanvas} packSelected={packSelected} total={totales.total} />
                  <div className={`btn-videollamada ${openCanvas ? '' : 'bg-primary'}  `}>
                    <div className="dropdown w-75 ">
                      {
                        isInBogota
                          ?
                          <>
                            <a className="btn btn-secondary w-100" target="_blank" href="https://calendly.com/d/47z-knd-tw7/intro-a-vivvidero">
                              Agendar videollamada
                            </a>
                            
                            {/*  <ul className="dropdown-menu z-3">

                              <ProfileCardMobile image={lina} url={'https://calendly.com/d/47z-knd-tw7/intro-a-vivvidero'} name={'Asesor'} />


                            </ul> */}
                          </>
                          :
                          !openCanvas
                            ?
                            <h5 className="text-center text-white">Precio estimado</h5>
                            :
                            null
                      }

                    </div>

                  </div>
                </div>
              </>
            }
          </div>

        </div>
      </div>

    </>
  );
};

