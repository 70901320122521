import React from 'react'
import nexusImage from "../../assets/images/Nexus-cocina.jpg"

export const ImageWithTag = () => {
    return (
        <div className="col-12 mb-2 col-md-6 position-relative tag" style={{ borderRadius: "0px", overflow: "hidden" }}>
            <img src={nexusImage} alt="cocina nexus" className="w-100 h-100" />
            <span>Estilo Boho Vintage</span>
            <span>Torre Nexus</span>
        </div>
    )
}
