import useToggleTrueFalse from "../../hooks/useToggleTrueFalse";
import logo from "../../assets/images/logoVivvidero-verde.png";
import { useContext, useEffect, useState } from "react";
import DataService from "../../services/data-service";
import "./_responsiveView.scss"
import { UserContext } from "../../context/UserContext/UserContext";


const ResponsiveView = ({ roomOpen, style, isDiscountBanner, getDiscount }) => {
  const [user] = useContext(UserContext)

  const details = useToggleTrueFalse();

  const [imgMobileSrc, setImgMobileSrc] = useState([])
  const dataService = new DataService()

  useEffect(() => {

    if (!details.state) {
      dataService.getAWSurl(user.projectName, style.toUpperCase())
        .then(data => setImgMobileSrc(data))
      return
    } else {
      dataService.getAWSurl(user.projectName, style.toUpperCase())
        .then(data => setImgMobileSrc(data))

    }

  }, [user.projectName, style])



  return (
    <section className={`${isDiscountBanner && getDiscount ? 'pt-4' : 'pb-0'}`}>
      <div className="row col-sm-12 d-md-none my-sm-0 my-md-3 p-3">
        <div className="col-12 d-flex flex-column justify-content-evenly align-items-center my-3 text-primary text-center px-4">
          <div className="flex-shrink-0">
            <img src={logo} alt="vivvidero brand" width="150" />
          </div>
          <hr className="w-75 text-primary" />
          <h3>Selecciona los items que deseas incluir en cada espacio</h3>
          
        </div>
        <div className="col-12 col-xxl-8 d-flex justify-content-center mt-2" style={{ height: "30vh", boxSizing: "border-box" }}>
          <div className="position-relative w-100 h-100">
            <div id="carouselExampleIndicators" className="carousel slide h-100 w-100" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active btnCarrusel" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" className="btnCarrusel" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" className="btnCarrusel" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" className="btnCarrusel" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" className="btnCarrusel" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner h-100" >
                <div className="carousel-item active" data-bs-interval="4000">
                  <img src={imgMobileSrc[0]} className=" img-fluid h-100 w-100 object-fit-contain" alt={roomOpen} />
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                  <img src={imgMobileSrc[1]} className=" img-fluid h-100 w-100" alt={roomOpen} />
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                  <img src={imgMobileSrc[2]} className=" img-fluid h-100 w-100" alt={roomOpen} />
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                  <img src={imgMobileSrc[3]} className=" img-fluid h-100 w-100" alt={roomOpen} />
                </div>
                <div className="carousel-item" data-bs-interval="4000">
                  <img src={imgMobileSrc[4]} className=" img-fluid h-100 w-100" alt={roomOpen} />
                </div>
              </div>
              {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResponsiveView;
