import React from 'react'
import { TotalesCanvas } from './TotalesCanvas'
import './_responsiveView.scss'
import lina from '../../assets/images/lina-profile.jpeg'

export const OffCanvasTotals = ({ setPackSelected, openCanvas, packSelected, total }) => {

    const isInBogota = localStorage.getItem('city')


    return (
        <>
            <div className={`bg-primary agendar-videollamada panel ${isInBogota ? 'isInBogotaPanel' : 'IsntInBogotaPanel'} ${openCanvas ? 'open' : ''}`}>
                {/* OCULTAMOS LA OPCION SOLO MATERIALES YA QUE NO VENDEMOS MAS  */}
                {/* <div className={`d-flex w-100 `}>
                    <div className="btn-group w-100" role="group" aria-label="Basic radio toggle button group">

                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onClick={() => setPackSelected("Basico")} />
                        <label className={`btn btn-outline-primary text-white ${packSelected === "Basico" ? '' : 'inactive-tab'}`} htmlFor="btnradio1">Obra</label>

                        <input type="radio" className="btn-check " name="btnradio" id="btnradio2" autoComplete="off" onClick={() => setPackSelected("Standard")} />
                        <label className={`btn btn-outline-primary text-white ${packSelected === "Standard" ? '' : 'inactive-tab'}`} htmlFor="btnradio2">Solo materiales</label>
                    </div>
                </div> */}

                <div className="offcanvas-totals w-100 px-3 pt-3 text-white">
                    <TotalesCanvas total={total} packSelected={packSelected} />
                    {
                        isInBogota
                        &&
                        <div className='d-flex justify-content-center align-items-center gap-3'>
                            <div style={{ width: '40px', height: '40px', borderRadius: '100%', overflow: 'hidden' }}>
                                <img src={lina} alt="credit-card" className="w-100" />
                            </div>
                            <p className='m-0 text-secondary fs-5' style={{ fontWeight: 500 }}>Personaliza tu cotización gratis</p>
                        </div>
                    }

                </div>


            </div>

        </>
    )
}
