import React, { useState } from 'react'
import DataService from '../../../services/data-service'

export const ModalWhatsappLeads = ({ lead }) => {

    const [wpLead, setWpLead] = useState()

    const dataService = new DataService()

    console.log(lead);
    const handleGetWpLead = () => {

        if (lead.interest === "remodelar vivienda usada") {
            dataService.getWpLeadRemodelacion(lead.info_remodelacion_fk)
                .then((data) => setWpLead(data))
        } else {
            dataService.getWpLeadAdecuacion(lead.info_adecuacion_fk)
                .then((data) => setWpLead(data))
        }


    }

    console.log(wpLead);
    return (
        <div >
            <button
                type="button"
                className="btn btn-primary w-100 h-100 mb-1 my-2 d-flex align-items-center justify-content-center hoverBtn"
                data-bs-toggle="modal"
                data-bs-target={`#modal${lead.id}`}
                onClick={handleGetWpLead}
            >
                <p className="mx-1 my-0">Detalles</p>
            </button>
            <div
                className="modal fade"
                id={`modal${lead.id}`}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>{lead.name} </h2>
                        </div>
                        <div className="modal-body">
                            {lead.interest === "remodelar vivienda usada" && wpLead ?
                                <>
                                    <p>Quiere empezar: {wpLead?.time} </p>
                                    <p>Su vivienda es un/a {wpLead?.type} </p>
                                    <p>El area de su apartamento es: {wpLead?.area} mts 2 </p>
                                    <p>Tiene {wpLead?.bedrooms} habitacion/es y {wpLead?.bathrooms} baño/s </p>
                                    <p>Tiene {wpLead?.bedrooms} habitacion/es y {wpLead?.bathrooms} baño/s </p>
                                    <p>Quiere remodelar {wpLead?.rooms} </p>
                                </>
                                : lead.interest === "adecuar vivienda en obra gris" && wpLead ?
                                <>
                                    <p>Quiere empezar: {wpLead?.time} </p>
                                    <p>El proyecto se llama {wpLead?.project_name} </p>
                                    <p>El area de su apartamento es: {wpLead?.area} mts 2 </p>
                                    <p>Presupuesto: {wpLead?.range} mts 2 </p>
                                    <p>Necesita financiamiento: {wpLead.needs_funding ? "Si" : "No"} </p>
                                </>
                                : <p>El usuario no termino el flujo</p>
                            }
                            
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
