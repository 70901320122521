import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApartmentContext } from '../../context/ApartmentContext/ApartmentContext';
import { UserContext } from '../../context/UserContext/UserContext';
import useLocalStorage from '../../hooks/useLocalStorage';
import logo from "../../assets/images/logoVivvidero-verde.png"
import DataService from '../../services/data-service';
import "./_proyectoPersonalizado.scss"
import { ImageWithTag } from '../../components';
import { Header } from '../../Layout/Header';
import { LoadingContext } from '../../context/LoadingContext/LoadingContext';

export const CustomProject = () => {
    const [user, setUser, isMobile] = useContext(UserContext);
    const [apartment, setApartment] = useContext(ApartmentContext);
    const [loading, setLoading] = useContext(LoadingContext)

    const [area, setArea] = useLocalStorage('area', 30);
    const [projectName, setProjectName] = useLocalStorage('projectName', '');
    const [projectType, setProjectType] = useLocalStorage('projectType', '');
    const [conocido, setConocido] = useLocalStorage("conocido", false);
    const [startMoment, setStartMoment] = useLocalStorage("startMoment", '');
    const [customProjectName, setCustomProjectName] = useState('');
    const [email] = useLocalStorage('email');


    const handleInputChange = e => setUser({ ...user, [e.target.name]: e.target.value });

    const service = new DataService();

    const navigate = useNavigate();

    const handleAreaInput = e => {
        setApartment({ ...apartment, area: e.target.value });
    }

    const handleSubmit = e => {
        /*  if (user.startMoment === '0') {
             e.preventDefault();
             return alert("Selecciona una opción de inicio");
         }
         if (user.budget === '0') {
             e.preventDefault();
             return alert("Selecciona una opción de presupuesto inicial");
         } */
        setLoading(true)
        e.preventDefault();
        service
            .customProjectSave(customProjectName, area, email)
            .then(() => {
                setLoading(false)
                navigate('/banos-habitaciones')
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            });
    };

    useEffect(() => {
        setConocido(false);
        setArea(apartment.area);
        /* setProjectName(user.projectName); */
        setStartMoment(user.startMoment);
    }, [user, apartment]);


    return (
        <section
            className="col-12  min-vh-100 d-flex flex-column justify-content-start align-items-center py-5 bg-tertiary ">
            <Header logoColor={logo} size={"150px"} />
            <div className='row d-flex justify-content-evenly align-items-center custom-project mt-5'>
                <form onSubmit={handleSubmit} className='col-12 col-md-4 d-flex flex-column justify-content-between justify-content-md-around align-items-center p-3' style={{ color: '#203F3C' }}>

                    <h2 className='px-md-3 text-center text-md-start my-3'>Ingresa los datos de tu proyecto</h2>
                    <div className='w-100'>
                        <h3 className="text-start">Nombre del proyecto</h3>
                        <div className="input-group mb-3 w-100">
                            <input
                                name='customProjectName'
                                type="text"
                                placeholder="Ejemplo: Torre Aragon, Bogotá"
                                className="form-control form-control-lg"
                                onChange={(e) => setCustomProjectName(e.target.value)}
                                required
                                defaultValue={customProjectName}
                            />
                        </div>
                    </div>
                    <div className='w-100'>
                        <h3 className="">¿Qué área tiene tu apartamento?</h3>

                        <div className="input-group mb-3 w-100">
                            <input
                                type="number"
                                name='area'
                                placeholder="Ejemplo: 30"
                                min={30}
                                max={70}
                                className="form-control form-control-lg"
                                value={area}
                                onChange={handleAreaInput}
                                required
                                onInvalid={e => e.target.setCustomValidity("Cotizador solo para aptos en obra gris menores a 50m² y mayores a 30m²")}
                                onInput={e => e.target.setCustomValidity("")}
                            />
                            {/* <span className="input-group-text" id="basic-addon2">m²</span> */}
                        </div>
                    </div>
                    {/* <div className='w-100'>
                        <h3>
                            ¿Cuándo te gustaría que iniciemos?
                        </h3>
                        <select
                            name="startMoment"
                            className="form-select form-select-lg"
                            onChange={handleInputChange}
                            value={user.startMoment}
                            required
                        >
                            <option disabled value={'0'}>¿Cuándo iniciamos?</option>
                            <option value={"Trimestre 1 del 2024"}>Trimestre 1 del 2024</option>
                            <option value={"Trimestre 2 del 2024"}>Trimestre 2 del 2024</option>
                            <option value={"Trimestre 3 del 2024"}>Trimestre 3 del 2024</option>
                            <option value={"Trimestre 4 del 2024"}>Trimestre 4 del 2024</option>
                        </select>

                    </div> */}
                    {/* <div className='w-100'>
                        <h3 className='my-4'>
                            ¿Cuánto dinero destinarás a tu remodelación?
                        </h3>
                        <select
                            className="form-select form-select-lg "
                            name="budget"
                            onChange={handleInputChange}

                        >
                            <option defaultChecked value="">
                                Presupuesto disponible
                            </option>
                            
                            {budgetOptions.map((opcion) => {
                                return (
                                    <option key={opcion} value={opcion}>
                                        {opcion}
                                    </option>
                                );
                            })}

                        </select>
                    </div> */}

                    <div className='w-100 d-flex justify-content-evenly m-3'>
                        <button type="submit" className="btn btn-primary btn-lg w-100" disabled={loading} >
                            {loading && <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>}
                            Continuar
                        </button>
                    </div>
                </form>
                {!isMobile &&
                    <ImageWithTag />
                }

            </div>


        </section>

    )
}

