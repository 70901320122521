import React, { createContext, useContext, useMemo, useState } from 'react';
import { UserSelectionContext } from './UserSelectionContext';

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
 
    const [apartment] = useContext(UserSelectionContext)
    const [user, setUser] = useState({
        name: '',
        email: '',
        phoneNumber: 0,
        projectName: '',
        startMoment: '0',
        /* canal: '', */
        apartment,
        googleSheetId: ''
    });
 
    const isMobile = useMemo(() => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent), [])

    

    return (
        <UserContext.Provider value={[user, setUser, isMobile]}>
            { children }
        </UserContext.Provider>
    )
}

export default UserContextProvider;