import React from 'react'

export const SingleCatalogue = ({ estilo, titulo, descripcion, submit }) => {
    return (
        <div className='col-10 offset-1 offset-md-0 col-md-3' >
            <div className='d-flex flex-column align-items-start text-primary' style={{ minHeight: "420px" }}>
                <div className='w-100'>
                    <img src={estilo} className='w-100' alt="estilo" />
                </div>

                <h4 className='w-100 fs-4 my-2 fw-bold' >
                    {titulo}
                </h4>

                <div>
                    <p className='fw-light' > {descripcion} </p>
                </div>
                <button className='btn btn-primary border-primary w-100' onClick={ ()=> submit(titulo)}>
                    Seleccionar
                </button>
            </div>
        </div>
    )
}
