import axios from "axios";

class DataService {
  constructor() {
    let service = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      withCredentials: true,
    });
    this.service = service;
  }

  login = (name, email, cellphone, pauta, utmSource, utmMedium, utmCampaign, utmContent, utmTerm, product) => {

    return this.service
      .post("/login", { name, email, cellphone, pauta, utmSource, utmMedium, utmCampaign, utmContent, utmTerm, product })
      .then(response => response.data);
  };

  arcLogin = (usuario) => {
    return this.service
      .post("/arc-login", usuario)
      .then((response) => response.data);
  };

  reCaptcha = () => {
    return this.service.post("/").then((response) => response.data);
  };

  getRoom = (roomName, area) => {
    return this.service
      .get(`/${roomName}`, { area })
      .then((response) => response.data);
  };

  getAllRooms = (numBaños, numHabitaciones, area, id, isProyect) => {
    return this.service
      .get(`/items/${numBaños}/${numHabitaciones}/${area}/${id ? id : 1}/${isProyect}`)
      .then((response) => response.data);
  };

  architectsRooms = () => {
    return this.service.get("/arquitectos").then((response) => response.data);
  };

  architects = (id) => {
    return this.service
      .get(`/arquitectos/${id}`)
      .then((response) => response.data);
  };

  updatePrices = (id, costo) => {
    return this.service
      .post("/update-prices", { id, costo })
      .then((response) => response.data);
  };

  getLeads = () => {
    return this.service.get("/leads").then((response) => response.data);
  };

  postLeads = (lead) => {
    return this.service
      .post("/post-lead", { lead })
      .then((response) => response.data);
  };

  updateAssignment = (id, assignment) => {
    return this.service
      .put(`/update-assignment/${id}`, { assignment })
      .then((response) => response.data);
  };

  getOpciones = () => {
    return this.service.get("/opciones").then((response) => response.data);
  };

  exportLead = (lead) => {
    return this.service
      .post("/export-lead", { lead })
      .then((response) => response.data);
  };

  exportApus = (lead) => {
    return this.service
      .post("/export-apus", { lead })
      .then((response) => response.data);
  };
  getWhatsappLeads = () => {
    return this.service.get("/whatsapp-leads").then((response) => response.data);
  };
  getWpLeadRemodelacion = (id) => {

    return this.service.get(`/wp-lead-remodelacion/${id}`).then((response) => response.data)
  }
  getWpLeadAdecuacion = (id) => {

    return this.service.get(`/wp-lead-adecuacion/${id}`).then((response) => response.data)
  }

  getKnownProjects = () => {
    return this.service
      .get("/known-projects")
      .then((response) => response.data);
  };

  editProject = (id, editProject) => {
    return this.service
      .put(`/edit-project/${id}`, { editProject })
      .then((response) => response.data);
  };

  addProject = (addProject) => {
    return this.service
      .post("/add-project", { addProject })
      .then((response) => response.data);
  };

  deleteProject = (id) => {
    return this.service
      .delete(`/delete-project/${id}`)
      .then((response) => response.data);
  };

  loggedIn = () => {
    return this.service.get("/loggedIn").then((response) => response.data);
  };

  logout = () => {
    return this.service.post("/logout").then((response) => response.data);
  };

  saveChannelAndDisscountCode = (email, canal, disscountCode) => {
    return this.service
      .post(`/saveChannel`, { email, canal, disscountCode })
      .then(response => response.data)
  };

  componentThreeSave = (projectName, proyectType, area, startMoment, budget, email, googleSheetId, city, sector) => {

    return this.service
      .post(`/saveComponentThree`, { projectName, proyectType, area, startMoment, budget, email, googleSheetId, city, sector })
      .then(response => response.data)
  };

  customProjectSave = (projectName, area, email) => {
    return this.service
      .post(`/saveCustomProject`, { projectName, area, email })
      .then(response => response.data)
  };


  numBañosSave = (numBanos, numHabs, email) => {
    return this.service
      .post(`/saveBanosHabs`, { numBanos, numHabs, email })
      .then(response => response.data)
  };

  postGrade = (gradeInfo) => {
    return this.service
      .post("/saveGrading", gradeInfo)
      .then((response) => response.data);
  }

  saveKitchenTypology = (kitchenTypology, email) => {
    return this.service
      .post('/saveKitchenTypology', { kitchenTypology, email })
      .then(response => response.data)
  }

  getDisscountCode = code => {
    return this.service
      .get(`/getSingleCode/${code}`).then(response => response.data)
  }

  getAWSurl = (project, style) => {
    return this.service
      .get(`/getFileUrl/${project}/${style}`).then(response => response.data.urls)
  }
}

export default DataService;
