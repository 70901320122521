import { useEffect, useState } from "react";
//create a hook to use localStorage
export const getSavedValue = (key, initialValue) => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) return JSON.parse(storedValue);

    if (initialValue instanceof Function) return initialValue();
    
    return initialValue;
}

const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        return getSavedValue(key, initialValue);
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(storedValue));
    }, [storedValue]);

    return [storedValue, setStoredValue];
}

export default useLocalStorage;