import React from 'react'
import close from '../../assets/icons/close-x.svg'


export const DiscountBanner = ({ setIsDiscount }) => {


    return (
        <div className="banner-descuento text-primary">
            <p>
                🥳 Ganaste obsequio #iluminatuvida
            </p>
            <button onClick={() => setIsDiscount(false)}>
                <img src={close} alt="close" />
            </button>
        </div>
    )
}
