import React from "react";
import { ModalWhatsappLeads } from "./ModalWhatsappLeads";

export const WhatsappLeadsRow = ({ lead, index }) => {
  return (
    <tr>
      <th>{index}</th>
      <td>{lead?.name} </td>
      <td>{lead?.phone_number.split("@")[0]} </td>
      <td>{lead?.email} </td>
      <td>{lead?.city} </td>
      <td>{lead?.interest} </td>
      <td>
        <ModalWhatsappLeads lead={lead} />
      </td>
    </tr>
  );
};
