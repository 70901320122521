import React, { useContext, useEffect, useState } from "react";
import "./_projects.scss"
import { Link, useNavigate } from "react-router-dom";
import { ApartmentContext } from "../../context/ApartmentContext/ApartmentContext";
import { UserContext } from "../../context/UserContext/UserContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import DataService from "../../services/data-service";
import { ImageWithTag } from "../../components";
import { SelectTime } from "../../components/UI/SelectTime";
import logo from "../../assets/images/logoVivvidero-verde.png"
import { LoadingContext } from "../../context/LoadingContext/LoadingContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from "../../components/UI/Tooltip";


export const Projects = () => {
  const [opciones, setOpciones] = useState([]);
  const [dataProyect, setDataProyect] = useState({});
  const [projectName, setProjectName] = useLocalStorage("projectName", "");
  const [proyectType, setProyectType] = useLocalStorage("proyectType", "");
  const [conocido, setConocido] = useLocalStorage("conocido", true);
  const [projectId, setProjectId] = useLocalStorage("projectId", "");
  const [area, setArea] = useLocalStorage("area", 0);
  const [numBaños, setNumBaños] = useLocalStorage("numBaños", 1);
  const [numHabitaciones, setNumHabitaciones] = useLocalStorage("numHabitaciones", 1);
  const [kitchenTypology, setKitchenTypology] = useLocalStorage('kitchenTypology', 'B');
  const [startMoment, setStartMoment] = useLocalStorage("startMoment", '');
  const [email] = useLocalStorage('email');

  const [proyects, setProyects] = useState({
    nombre: "",
    tipo: "",
    city: "",
    budget: "",
    startMoment: "",
    sector: ""
  });
  const [apartment, setApartment] = useContext(ApartmentContext);
  const [user, setUser, isMobile] = useContext(UserContext);
  const [loading, setLoading] = useContext(LoadingContext)
  const history = useNavigate();

  const dataService = new DataService();

  /* Peticion a DB de todos los proyectos predefinidos */
  useEffect(() => {
    dataService.getOpciones().then((opciones) => {
      setOpciones(opciones);
    });
    setProjectName("");
  }, []);

  /* Cada vez que el objeto state "proyects", se setea el dataProyect (Contiene la info del proyecto seleccionado)  */
  useEffect(() => {
    setDataProyect(
      opciones
        .filter((opcion) => {
          return opcion.nombre === proyects.nombre;
        })
        .filter((opcion) => {
          return opcion.tipo === proyects.tipo;
        }) || []
    );
  }, [proyects]);

  /* Crea un array vacio y lo llena con los nombres de los proyectos para luego renderizar las <option> del <select> */
  const proyectsNames = [];
  opciones.forEach((opcion) => {
    if (proyectsNames.indexOf(opcion.nombre) === -1) {
      proyectsNames.push(opcion.nombre);
    }
  })
  proyectsNames.push("Proyecto personalizado");

  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  /* Handle para el nombre del proyecto, setea el nombre y vuelve a string vacio el tipo */
  const handleInputName = (e) => {
    const value = e.target.value;
    setProyects({
      ...proyects,
      tipo: "",
      nombre: value,
    });
    setProjectName(value);
    setUser({
      ...user,
      projectName: value,
    });
    // No cambiamos el estado de isOpen aquí
  };

  const handleInputClick = () => {
    setIsOpen(true);
  };

  const handleOptionClick = (opcion) => {
    if (opcion === "Proyecto personalizado") {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 4000);
      setProjectName(opcion);
      setProyects({
        ...proyects,
        tipo: "",
        nombre: opcion,
      });
      setUser({
        ...user,
        projectName: opcion,
      });
    } else {
      setProyects({
        ...proyects,
        tipo: "",
        nombre: opcion,
      });
      setProjectName(opcion);
      setUser({
        ...user,
        projectName: opcion,
      });
    }
    setIsOpen(false);
  };

  /* Handle para el tipo del proyecto, setea el tipo */
  const handleType = (e) => {
    setProyects({
      ...proyects,
      [e.target.name]: e.target.value,
    });
    setProyectType(e.target.value);
  };

  useEffect(() => {
    saveOnLocalStorage();
  }, [dataProyect, user]);

  const saveOnLocalStorage = () => {
    if (dataProyect[0]) {
      setArea(dataProyect[0].area);
      setNumBaños(parseInt(dataProyect[0].b_cant));
      setNumHabitaciones(parseInt(dataProyect[0].h_cant));
      setProjectId(dataProyect[0].id);
      setKitchenTypology(dataProyect[0].tipologia);
      setConocido(true);
    }
    setStartMoment(user.startMoment)
  };


  /* Handle para el submit, valida que nombre y tipo no esten vacios, y guarda los datos de area, baños y habs en local storage */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!proyectsNames.includes(proyects.nombre)) {
      return alert("Por favor selecciona un proyecto de la lista");
    }

    if (proyects.nombre === "Proyecto personalizado") {
      if (!proyects.nombre || !proyects.startMoment || !proyects.budget || !proyects.city) {
        return alert("Todos los campos son obligatorios");
      }
    } else {
      if (!proyects.nombre || !proyects.tipo || !proyects.startMoment || !proyects.budget || !proyects.city) {
        return alert("Todos los campos son obligatorios");
      }
    }


    setLoading(true)

    dataService
      .componentThreeSave(projectName, proyectType, area, proyects.startMoment, proyects.budget, email, user?.googleSheetId, proyects.city, proyects.sector)
      .then(() => {
        return dataService.numBañosSave(numBaños, numHabitaciones, email);
      })
      .then(() => {
        if (proyects.nombre === "Proyecto personalizado") {
          setLoading(false)
          return history("/personal-project");
        } else {
          setApartment({
            ...apartment,
            area: parseFloat(dataProyect[0].area),
          });
          setLoading(false)
          history("/catalog");
        }

      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      });
  };


  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    setProyects({ ...proyects, [e.target.name]: e.target.value });

    if (e.target.name === 'city') {
      if (e.target.value === 'Bogotá' || e.target.value === 'Chía' || e.target.value === 'Cajicá') {

        localStorage.setItem('city', JSON.stringify(e.target.value))
      } else {
        localStorage.removeItem('city')
      }
    }

  };





  return (
    <section
      className="col-12 d-flex flex-column align-items-center justify-content-between min-vh-100 bg-tertiary">
      {
        isMobile &&
        <ImageWithTag />
      }
      <div className="row form-and-carrusel px-3 flex-grow-1">
        <div className="col-md-4 d-flex flex-column justify-content-center align-items-center h-100" >
          <div>
            {
              !isMobile &&
              <div className="w-100 d-flex justify-content-center align-items-center mb-5">
                <img src={logo} alt="vivvidero" className="w-50" />
              </div>
            }
            <h2 className="text-center text-md-start text-primary mb-4">
              ¿Dónde compraste tu apartamento?
            </h2>
            <form >
              <label className="fw-semibold">Nombre del proyecto</label>
              <div className="box-input-glass">
                <div className="input-glass">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                  <input
                    type="text"
                    name="nombre"
                    value={projectName}
                    onChange={handleInputName}
                    onClick={handleInputClick}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="position-relative">
                {isOpen && (
                  <div className="custom-dropdown col-11 col-md-12 shadow">
                    {(() => {
                      const searchTerm = projectName.toLowerCase().trim();

                      let filteredProjects = proyectsNames;

                      if (searchTerm) {
                        filteredProjects = proyectsNames.filter(project => {
                          const fullName = project.toLowerCase();
                          return fullName.includes(searchTerm) && fullName !== searchTerm;
                        });
                      }

                      if (filteredProjects.length === 0) {
                        return (
                          <div className="dropdown-row p-2" onClick={() => {
                            handleOptionClick("Proyecto personalizado");
                          }}>
                            ¿No encuentras tu proyecto? Crea tu proyecto personalizado
                          </div>
                        );
                      }

                      return filteredProjects.map((opcion) => (
                        <div key={opcion} className="dropdown-row p-2 border" onClick={() => handleOptionClick(opcion)}>
                          <FontAwesomeIcon icon={faBuilding} />
                          {opcion}
                        </div>
                      ));
                    })()}
                  </div>
                )}

                {showTooltip && (
                  <Tooltip isMobile={isMobile} message="Cotiza con los datos específicos de tu proyecto" />
                )}
              </div>

              {/* <span className="onboarding-lead fw-semibold">Escribe el nombre de tu proyecto, si no esta en la lista elige la opción de “Proyecto personalizado”</span> */}
              {/* <select
                className="form-select form-select-lg my-4"
                name="nombre"
                onChange={handleInputName}
                required
              >
                <option defaultChecked value="">
                  Nombre del proyecto
                </option>
                
                {proyectsNames.map((opcion) => (
                  <option key={opcion} value={opcion}>
                    {opcion}
                  </option>
                ))}
                <option value="noProject">
                  No tengo un proyecto aún
                </option>
              </select> */}

              {
                proyectsNames.includes(proyects.nombre)
                  ?
                  proyects.nombre !== "Proyecto personalizado"
                  &&
                  <>
                    <label className="w-100 mt-3 fw-semibold">Tipo de proyecto</label>
                    <select
                      className="form-select form-select-lg"
                      name="tipo"
                      onChange={handleType}
                      required
                      disabled={proyects.nombre === "Proyecto personalizado"}
                    >
                      <option defaultChecked value="">
                        Tipo de proyecto
                      </option>
                      {/* Renderiza options con los tipos de los proyectos segun su nombre */}
                      {opciones.map((opcion) => {
                        if (opcion.nombre === projectName) {
                          return (
                            <option key={opcion.tipo} value={opcion.tipo}>

                              {opcion.tipo}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </>

                  :
                  null
              }


              {/* Pregunta por presupuesto disponible */}
              <label className="w-100 mt-3 fw-semibold"> ¿Dónde está ubicado tu apartamento? </label>
              <select
                className="form-select form-select-lg"
                name="city"
                onChange={handleInputChange}

                required
              >
                {
                  proyects.city === "" ?
                    <option defaultChecked value=""></option>
                    :
                    <option defaultChecked value={proyects.city}>{proyects.city}</option>
                }

                <option value="Bogotá">Bogotá</option>
                <option value="Chía">Chía</option>
                <option value="Cajicá">Cajicá</option>
                <option value="Sabana">Sabana</option>
                <option value="Otro">Otro</option>
              </select>

              {
                user.city === "Bogotá" &&
                <>
                  <label className="w-100 mt-3 fw-semibold"> Sector </label>
                  <select required name="sector" value={proyects?.sector} onChange={handleInputChange} className="form-select form-select-lg">
                    <option value="">Sector</option>
                    <option value="Usaquén">Usaquén</option>
                    <option value="Chapinero">Chapinero</option>
                    <option value="Santa Fé">Santa Fé</option>
                    <option value="San cristobal">San cristobal</option>
                    <option value="Usme">Usme</option>
                    <option value="Tunjuelito">Tunjuelito</option>
                    <option value="Kennedy">Kennedy</option>
                    <option value="Bosa">Bosa</option>
                    <option value="Fontibón">Fontibón</option>
                    <option value="Engativá">Engativá</option>
                    <option value="Suba">Suba</option>
                    <option value="Barrios Unidos">Barrios Unidos</option>
                    <option value="Teusaquillo">Teusaquillo</option>
                    <option value="Puente Aranda">Puente Aranda</option>
                    <option value="Candelaria">Candelaria</option>
                    <option value="Antonio Nariño">Antonio Nariño</option>
                    <option value="Los Mártires">Los Mártires</option>
                    <option value="Rafael Uribe Uribe">Rafael Uribe Uribe</option>
                    <option value="Ciudad Bolivar">Ciudad Bolivar</option>
                  </select>
                </>

              }
              <SelectTime user={user} setUser={setUser} proyects={proyects} setProyects={setProyects} />
              <label className="w-100 mt-3 fw-semibold"> ¿Cuanto deseas invertir? </label>
              <select required name="budget" onChange={handleInputChange} className="form-select form-select-lg">
                <option value="">¿Cuanto deseas invertir?</option>
                <option value="20-30 Millones">20-30 Millones</option>
                <option value="30-45 Millones">30-45 Millones</option>
                <option value="45-60 Millones">45-60 Millones</option>
                <option value="60 + Millones">60 + Millones</option>
              </select>

              <div className="d-flex flex-column justify-content-between align-items-center w-100 my-3">
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="btn btn-primary btn-lg w-100 mb-3"
                  disabled={loading}
                >
                  {loading && <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>}
                  Continuar
                </button>
                {/* <Link className="btn btn-secondary w-100 mb-3" style={{ "color": "#203F3C" }} to={"/personal-project"}> No tengo un proyecto aún</Link> */}
              </div>
            </form>

          </div>

        </div>
        {
          !isMobile &&
          <ImageWithTag />
        }
      </div>



    </section>
  );
};
