import React, { useState } from "react";
import DataService from "../../../services/data-service";
import { ModalApusLeads } from "./ModalApusLeads";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faBoxesPacking,
} from "@fortawesome/free-solid-svg-icons";

export const LeadsRow = ({ lead }) => {
  const [checked, setChecked] = useState(lead?.asignado || false);
  const [loading, setLoading] = useState(false);

  const dataService = new DataService();
  const sheetsWindow = null;

  const handleCheck = () => {
    setLoading(true);
    setChecked(!checked);
    dataService.updateAssignment(lead.id, !checked).then(() => {
      setLoading(false);
    });
  };

  const handleLeadClick = () => {
    dataService.exportLead(lead);

    if (sheetsWindow === null || sheetsWindow.closed) {
      window.open(
        "https://docs.google.com/spreadsheets/d/1_xwwC76SXia4_1oR0LQwAEdxfOBKdrOKHbH5Y_ZotMM/edit#gid=1255670085",
        sheetsWindow
      );
    } else {
      sheetsWindow.focus();
    }
  };

  const handleApuClick = () => {
    dataService.exportApus(lead);

    if (sheetsWindow === null || sheetsWindow.closed) {
      window.open(
        "https://docs.google.com/spreadsheets/d/1_xwwC76SXia4_1oR0LQwAEdxfOBKdrOKHbH5Y_ZotMM/edit#gid=1613660502",
        sheetsWindow
      );
    } else {
      sheetsWindow.focus();
    }
  };

  return (
    <tr>
      <th scope="row">{lead.id}</th>
      <td>
        <div className="form-check form-switch d-flex justify-content-center align-items-center">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            disabled={loading}
            onChange={handleCheck}
            id={lead.id}
            checked={checked}
          />
        </div>
      </td>
      <td>{lead?.nombre}</td>
      <td>{lead?.celular}</td>
      <td>{lead?.email}</td>
      <td>{lead?.date}</td>
      <td>{lead?.canal}</td>
      <td>{lead?.fecha_inicio}</td>
      <td>{lead?.estilo}</td>
      <td>{lead?.proyecto}</td>
      <td>{lead?.n_baños}</td>
      <td>{lead?.n_habitaciones}</td>
      <td>{lead?.area} Mts2</td>
      <td>${lead?.total}</td>
      <td>
        {/* boton para ver modal con detalles de apus */}
        {lead.jdata && <ModalApusLeads lead={lead} />}
      </td>
      <td>
        {lead.jdata && (
          <>
            <button
              className="btn btn-secondary d-flex justify-content-between align-items-center w-100 mb-1 hoverBtn"
              onClick={handleLeadClick}
            >
              <FontAwesomeIcon icon={faFileExport} />
              <p className="mx-1 my-0">Lead</p>
            </button>
            <button
              className="btn btn-primary d-flex justify-content-between align-items-center w-100 hoverBtn"
              onClick={handleApuClick}
            >
              <FontAwesomeIcon icon={faBoxesPacking} />
              <p className="mx-1 my-0">APUS</p>
            </button>
          </>
        )}
      </td>
      {/* modal para ver detalles de apus */}
    </tr>
  );
};
