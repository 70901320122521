import Checkbox from './Checkbox';

const Subespacios = props => {
  const h = (superapu, obj, costo) => {
    props.updateApus(superapu, obj, costo);
  };

  const updateSubtotal = val => {
    props.updateSubtotal(val);
  };

  return (
    <div className=''>
      <p className='text-primary mb-0 fs-5'>{props.roomName}</p>
      {
        props.apus
          .map(apu => <Checkbox apu={apu} key={apu.superApu} seleccionados={props.seleccionados} value={apu.superApu} name={apu.nombre} updateApus={h} costo={apu.costo} updateSubtotal={updateSubtotal} />)
      }
    </div>
  )
}

export default Subespacios