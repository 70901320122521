import React, { useEffect, useState } from 'react';
import useToggleTrueFalse from '../../hooks/useToggleTrueFalse';
import './_espaciosDropdown.scss';

const Checkbox = props => {
    const checked = useToggleTrueFalse();
    // console.log('Apu', props.apu.superApu, 'Seleccionados', props.seleccionados);



    useEffect(() => {
        checkSelection();
    }, [props.seleccionados]);

    const checkSelection = () => {
        if (props?.seleccionados?.map(apu => apu.superApu).includes(props.apu.superApu)) {
            checked.toggleTrue();
            return;
        }
        checked.toggleFalse();
    };

    return (
        <div className="mb-2 mt-0">


            <label className="text-dark checkbox-label checkbox-label-apus w-100 position-relative">
                <input
                    className="checkbox-1"
                    onChange={() => props.updateApus(props.value, props.apu, props.costo)}
                    type="checkbox"
                    value={props.costo}
                    id=""
                    checked={checked.state}
                />
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                {/* <!-- Texto --> */}
                <span class="custom-radio-checkbox__text">{props.name} &nbsp;&nbsp; ({props.apu.id})</span>

            </label>
        </div>
    )
}

export default Checkbox