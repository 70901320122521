import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataService from "../../../services/data-service";
import { ModalEditProject } from "./Edit/ModalEditProject";
import { ModalAddProject } from "./Add/ModalAddProject";
import { DeleteProject } from "./Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "../../../components";

export const KnownProjects = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [request, setRequest] = useState(false);

  const headings = [
    "ID",
    "Edición",
    "Nombre",
    "Constructora",
    "Tipo",
    "Area",
    "N°Baños",
    "N°Hab",
    "Cocina",
    "Tipologia",
    "Aseo",
    "Baño 1°",
    "Hab 1°",
    "Sala/Com",
    "Baño 2°",
    "Hab 2°",
    "Hab 3°",
    "Hall",
    "Estudio",
  ];
  const history = useNavigate();

  const dataService = new DataService();

  useEffect(() => {
    setLoading(true);
    dataService
      .getKnownProjects()
      .then((data) => {
        setProjects(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [request]);

 

  return (
    <div
      className="col-12 d-flex flex-column justify-content-around align-items-center p-3"
      style={{ minHeight: "600px" }}
    >
      <h2
        style={{
          color: "white",
          fontSize: "60px",
          textShadow: "#EBC991 3px 5px 5px",

        }}
      >
      <FontAwesomeIcon icon={faBuilding} /> 
       {" Proyectos"}
      </h2>
      <div className="d-flex align-self-start">
        <button
          className="btn btn-lg btn-secondary d-flex align-self-start m-3 hoverBtn"
          onClick={() => history("/main-view")}
        >
          Volver
        </button>
        <ModalAddProject headings={headings} setRequest={setRequest} request={request} />
      </div>

      <div className="table-responsive w-100">
        <table className="table table-primary table-hover ">
          <thead>
            {/*  ENCABEZADOS */}
            {}
            <tr className="table-secondary">
              {headings.map((heading) => (
                <th key={heading}>{heading}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {/*  CELDAS */}
            {projects?.map((project) => (
              <tr key={project.id}>
                <th scope="row">{project.id}</th>
                <td>
                  <ModalEditProject project={project} headings={headings} setProjects={setProjects} request={request} setRequest={setRequest}  />
                  <DeleteProject project={project} setProjects={setProjects} request={request} setRequest={setRequest}  />
                </td>
                <td>{project.nombre}</td>
                <td>{project.constructora}</td>
                <td>{project.tipo}</td>
                <td>{project.area}</td>
                <td>{project.b_cant}</td>
                <td>{project.h_cant}</td>
                <td>{project.cocina}</td>
                <td>{project.tipologia}</td>
                <td>{project.lavanderia}</td>
                <td>{project.baño_principal}</td>
                <td>{project.hab_principal}</td>
                <td>{project.sala_comedor}</td>
                <td>{project.baño1}</td>
                <td>{project.hab1}</td>
                <td>{project.hab2}</td>
                <td>{project.hall}</td>
                <td>{project.estudio}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <Spinner />}
      </div>
    </div>
  );
};
