import React, { createContext, useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';

export const ApartmentContext = createContext();

const ApartmentContextProvider = ({ children }) => {

    const [apartment, setApartment] = useState({
        area: 30,
        baños: 1,
        habitaciones: 2,
        estilo: 'BOHO',
        total: 0,
        comments: '',
        isProyect: false,
        roomsPicked: [],
    });
 
    return (
        <ApartmentContext.Provider value={[apartment, setApartment]}>
            { children }
        </ApartmentContext.Provider>
    )
}

export default ApartmentContextProvider;