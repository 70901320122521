import React, { createContext, useState } from 'react';
import { 
    INITIAL_STATE_BAÑO_PRINCIPAL, 
        INITIAL_STATE_BAÑO_SECUNDARIO, 
        INITIAL_STATE_HABITACION_PRINCIPAL, 
        INITIAL_STATE_HABITACION_SECUNDARIA,
        INITIAL_STATE_COCINA,
        INITIAL_STATE_SALA_COMEDOR,
        INITIAL_STATE_CUARTO_ASEO 
    } from './consts/apusPreseleccionados';

export const UserSelectionContext = createContext();

const UserSelectionContextProvider = ({ children }) => {
    const [apusSelected, setApusSelected] = useState({
        'Baño principal': [],
        'Baño secundario': [],
        'Baño terciario': [],
        'Habitación principal': [],
        'Habitación secundaria': [],
        'Habitación terciaria': [],
        'Sala / Comedor': [],
        'Cuarto aseo': [],
        Cocina: []
    });

    return (
        <UserSelectionContext.Provider value={[apusSelected, setApusSelected]}>
            { children }
        </UserSelectionContext.Provider>
    )
}

export default UserSelectionContextProvider;