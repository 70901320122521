// import { Container } from '@mui/material';
import React from "react";
import { Link } from "react-router-dom";
import vivvidero_white from "../../images/vivvidero 4  - copia 1.png";
import "../../scss/_variables.scss";

export const MainView = () => {
  return (
    <div className="container-11">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          height: "100%",
        }}
      >
        <img
          src={vivvidero_white}
          alt="Vivvidero Brand"
          width={150}
          style={{ marginTop: "20px" }}
        />
        <h1
          style={{
            color: "white",
            fontSize: "60px",
            textShadow: "#EBC991 3px 5px 5px",
            textAlign: "center",
          }}
        >
          Bienvenido Vivvicrack
        </h1>
        <p
          
          style={{
            fontSize: "3em",
            textShadow: "#2B6662 3px 5px 5px",
            color: "#F8F1F2",
            textAlign: 'center'
          }}
        >
          Que quieres hacer hoy?
        </p>
        <div 
          style={{
            height: "200px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="d-flex flex-column flex-sm-row"
        >
          <Link
            type="button"
            className="btn btn-light btn-lg mx-3 hoverBtn"
            to={"/update-prices"}
          >
            Actualizar Precios
          </Link>
          <Link
            type="button" 
            className="btn btn-light btn-lg mx-3 hoverBtn"
            to={"/view-leads"}
          >
            Ver Leads
          </Link>
          <Link
            type="button" 
            className="btn btn-light btn-lg mx-3 hoverBtn"
            to={"/view-leads-renovadora"}
          >
            Ver Leads Renovadora
          </Link>
          <Link
            type="button"
            className="btn btn-light btn-lg mx-3 hoverBtn"
            to={"/known-projects"}
          >
            Proyectos
          </Link>
          <Link
            type="button"
            className="btn btn-light btn-lg mx-3 hoverBtn"
            to={"/whatsapp-leads"}
          >
            WhatsApp Leads
          </Link>
        </div>
        <Link
          to={"/architects"}
          type="button"
          className="btn btn-secondary btn-lg hoverBtn"
          style={{ width: "30%" }}
        >
          Volver
        </Link>
      </div>
    </div>
  );
};

