import axios from "axios";

class DataServiceRenov {
  constructor() {
    let serviceRenov = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      withCredentials: true,
    });
    this.serviceRenov = serviceRenov;
  }

  getLeadsRenovadora = () => {
    return this.serviceRenov.get("/leads-renovadora").then((response) => response.data);
  };
}

export default DataServiceRenov;
