import React from "react";

export const SelectTime = ({ user, setUser, proyects, setProyects }) => {

  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1; // Los meses en JavaScript empiezan desde 0
  month = month < 10 ? '0' + month : month; // Asegurarse de que el mes siempre tenga dos dígitos
  const currentYearMonth = `${year}-${month}`;

  const handleInputChange = e => {
    setProyects({ ...proyects, [e.target.name]: e.target.value });
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  return (
    <>
      <label className="mt-3 fw-semibold"> ¿Cuándo quieres remodelar? </label>
      <input
        type="month"
        min={currentYearMonth}
        name="startMoment"
        className="form-select form-select-lg"
        style={{ backgroundImage: 'none' }}
        onChange={handleInputChange}
        value={proyects.startMoment}
        required
        disabled={proyects?.nombre === "No está en el listado"}
      />
    </>
  );
};
