import queryString from "query-string";

//test de inyección de software
export const validateUtm = (query)=> {
    const params = queryString.parse(query);
    const regex = /^[a-zA-Z0-9 _-]+$/;

    return {
        utmSource: typeof params.utm_source === 'string' && regex.test(params.utm_source) ? params.utm_source : '',
        utmMedium: typeof params.utm_medium === 'string' && regex.test(params.utm_medium) ? params.utm_medium : '',
        utmCampaign: typeof params.utm_campaign === 'string' && regex.test(params.utm_campaign) ? params.utm_campaign : null,
        utmContent: typeof params.utm_id === 'string' && regex.test(params.utm_id) ? params.utm_id : null,
        utmTerm: typeof params.utm_term === 'string' && regex.test(params.utm_term) ? params.utm_term : null,
        product: typeof params.product === 'string' && regex.test(params.product) ? params.product : null
    };
}