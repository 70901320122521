import React, { useState } from 'react'

export const InputSelectorTypology = ({ setNewProject, newProject }) => {

    const options = [
        { tipo: "A", value: 1 },
        { tipo: "AA", value: 2 },
        { tipo: "B", value: 3 },
        { tipo: "BB", value: 4 },
        { tipo: "C", value: 5 },
        { tipo: "CC", value: 6 }
    ]

    const handleAdd = (e) => {
        setNewProject({
            ...newProject,
            [e.target.name]: e.target.value,
        });
    };



    return (
        <td>
            <select className="form-select" aria-label="Default select example" onChange={handleAdd} name={"Tipologia"}>
                <option value={""}> </option>
                {options.map((option, index) => <option key={index} value={option.value}> {option.tipo} </option>)}
            </select>
        </td>
    );
}
