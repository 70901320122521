import React from "react";

export const InputAddProject = ({ setNewProject, newProject, heading }) => {
  const handleAdd = (e) => {
    setNewProject({
      ...newProject,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <td>
      <input
        type="text"
        name={heading}
        className="form-control"
        style={{ width: "100px" }}
        value={newProject[heading]}
        onChange={handleAdd}
      />
    </td>
  );
};
