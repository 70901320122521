import React, { useContext, useMemo, useState } from "react";
import { UserSelectionContext } from "../../context/UserContext/UserSelectionContext";
import useToggleTrueFalse from "../../hooks/useToggleTrueFalse";
import "./_espaciosDropdown.scss";
import down_arrow from "../../assets/icons/down_arrow.png";
import right_arrow from "../../assets/icons/Arrow---Right-2.png"
import Subespacios from "./Subespacios";
import { UserContext } from "../../context/UserContext/UserContext";
import { convertWithCommas } from "../../utils/helpers/convertWithCommas";

const EspaciosDropdown = (props) => {
  const [apusSelected, setApusSelected] = useContext(UserSelectionContext);
  const userContext = useContext(UserContext)
  const localApus = useMemo(() => apusSelected[props.roomName], [apusSelected])
  const checked = useToggleTrueFalse();
  const [isOpen, setIsOpen] = useState(false)

  const subTotal = useMemo(() => {
    if (localApus) {
      const sum = localApus.reduce((acc, curr) => acc + curr.costo, 0);
      return sum;
    }
    return 0
  }, [localApus])


  const handleOpenAndClose = () => {
    setIsOpen(!isOpen)

    props.changeName(props.roomName);
  };

  const handleApusChange = (superapu, apu, costo) => {
    if (localApus.map(apu => apu.superApu).includes(superapu)) {
      checked.toggleFalse();
      setApusSelected({
        ...apusSelected,
        [props.roomName]: [
          ...apusSelected[`${props.roomName}`].filter(
            (apu1) => apu1.superApu !== apu.superApu
          ),
        ],
      });
      return;
    }
    checked.toggleFalse();
    setApusSelected({ ...apusSelected, [props.roomName]: [...apusSelected[props.roomName], apu], });
  };

  const handleSelectAll = () => {
    const arr = props.apus.map((apu) => apu.apu).flat();
    if (!checked.state) {
      checked.toggleTrue();
      setApusSelected({ ...apusSelected, [props.roomName]: arr, });
      return;
    }

    checked.toggleFalse();
    if (localApus.length === arr.length) {
      setApusSelected({
        ...apusSelected,
        [props.roomName]: [],
      });
      return;
    }
    setApusSelected({
      ...apusSelected,
      [props.roomName]: [...apusSelected[props.roomName]],
    });
  };

  return (
    <>
      <button onClick={handleOpenAndClose} className={`w-100 btn btn-lg mt-3 d-flex justify-content-between align-items-center ${isOpen ? 'bg-primary text-white' : 'espacio-inactive'}`} >
        <p className="my-0 mx-1 ">
          {
            isOpen
              ?
              <img src={down_arrow} alt="arrow" className="mx-1" />
              :
              <img src={right_arrow} alt="arrow" className="mx-1" />
          }

          {props.roomName}
        </p>{" "}
        <p className="my-0">
          ${convertWithCommas(subTotal)}M
        </p>

      </button>

      {isOpen ? (
        <>
          <div className="mb-2 my-3 h-25 w-100 d-flex justify-content-between align-items-center px-2 ">

          </div>

          <div className={`w-100 display-apus ${userContext[2] ? '' : 'bg-white px-3'} `}>
            <label className="text-dark checkbox-label p-1 py-md-2">
              <input className="checkbox-1 " checked={checked.state} onChange={handleSelectAll} type="checkbox" value={props.value} /> 
              <span className="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
              <span className="custom-radio-checkbox__text">Seleccionar todo</span>
            </label>
            {props.apus.map((room, index) => (
              <Subespacios key={index} roomName={room.subcapitulo} apus={room.apu} seleccionados={localApus} updateApus={handleApusChange} subTotal={subTotal} />
            ))}
          </div>
        </>
      ) :
        <>
        </>
      }
    </>
  );
};

export default EspaciosDropdown;