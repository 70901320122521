import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import DataService from "../../../../services/data-service";
import { InputEditProject } from "./InputEditProject";
import { InputSelectorEditTypology } from "./InputSelectorEditTypology";
import { ButtonLoading } from "../../../../components";

export const ModalEditProject = ({ project, headings, request, setRequest }) => {
  const [editProject, setEditProject] = useState({
    id: project.id,
    nombre: project.nombre,
    constructora: project.constructora,
    tipo: project.tipo,
    area: project.area,
    b_cant: project.b_cant,
    h_cant: project.h_cant,
    cocina: project.cocina,
    tipologia: project.tipologia,
    sala_comedor: project.sala_comedor,
    lavanderia: project.lavanderia,
    hab_principal: project.hab_principal,
    baño_principal: project.baño_principal,
    baño1: project.baño1,
    hab1: project.hab1,
    hab2: project.hab2,
    hall: project.hall,
    estudio: project.estudio,
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const dataService = new DataService();

  const handleEdit = () => {
    setBtnLoading(true);
    dataService
      .editProject(project.id, editProject)
      .then(() => {
        console.log("Project edited");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setBtnLoading(false);
        alert("Proyecto editado correctamente");
        setRequest(!request);
      });

  };


  return (
    <>
      {btnLoading ? (
        <ButtonLoading />
      ) : (
        <button
          className="btn btn-primary m-1 hoverBtn"
          data-bs-toggle="modal"
          data-bs-target={`#modal${project.id}`}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
      )}

      <div
        className="modal fade"
        id={`modal${project.id}`}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Actualiza el valor que desees</h4>
            </div>
            <div className="modal-body">
              <div className="table-responsive w-100">
                <table className="table table-primary table-hover ">
                  <thead>
                    <tr>
                      {headings.map((heading) => {
                        if (heading === "Edición") {
                          return;
                        }
                        return <th key={heading}>{heading}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{project.id}</th>
                      <td>
                        <InputEditProject
                          value={editProject.nombre}
                          name={"nombre"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                          readOnly={true}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.constructora}
                          name={"constructora"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                          readOnly={true}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.tipo}
                          name={"tipo"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.area}
                          name={"area"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.b_cant}
                          name={"b_cant"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.h_cant}
                          name={"h_cant"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.cocina}
                          name={"cocina"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputSelectorEditTypology
                          value={editProject.tipologia}
                          name={"tipologia"}
                          editProject={editProject}
                          setEditProject={setEditProject} />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.lavanderia}
                          name={"lavanderia"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.baño_principal}
                          name={"baño_principal"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.hab_principal}
                          name={"hab_principal"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.sala_comedor}
                          name={"sala_comedor"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.baño1}
                          name={"baño1"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.hab1}
                          name={"hab1"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.hab2}
                          name={"hab2"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.hall}
                          name={"hall"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                      <td>
                        <InputEditProject
                          value={editProject.estudio}
                          name={"estudio"}
                          editProject={editProject}
                          setEditProject={setEditProject}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary hoverBtn"
                data-bs-dismiss="modal"
                onClick={handleEdit}
              >
                Editar
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
